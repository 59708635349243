import React, { memo } from 'react';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';

function Opcoes({
    className,
    dados,
    clicouWhatsapp,
    clicouEditar,
    clicouCliente,
    clicouFicha,
    clicouConcluir,
    clicouEmail,
    clicouSms
}){

    return <div className={[className, styles.opcoes, 'rounded-bottom'].join(' ')}>

        {dados.concluido === 'N' &&
            <>
                <OpcaoPadrao 
                    label='Concluir'
                    icone={["fas", 'check-square']}
                    onClick={clicouConcluir}
                />
            </>
        }

        <OpcaoPadrao 
            label={dados._ficticio ? 'Editar Original' : 'Editar'}
            icone={["fas", 'pencil-alt']}
            onClick={clicouEditar}
        />

        {dados.alvo_tabela === 'clientes_fichas' &&
            <OpcaoPadrao 
                label='Cadastro da ficha'
                icone={["fas", 'clipboard-list']}
                onClick={clicouFicha}
            />
        }

        <OpcaoPadrao 
            label='Cadastro do cliente'
            icone={["fas", 'user']}
            onClick={clicouCliente}
        />
        
        <OpcaoPadrao 
            label='Enviar email'
            icone={["fas", 'envelope']}
            onClick={clicouEmail}
        />
        
        <OpcaoPadrao 
            label='Enviar sms'
            icone={["fas", 'sms']}
            onClick={clicouSms}
        />

        <OpcaoPadrao 
            label='Enviar whatsapp'
            icone={["fab", 'whatsapp']}
            onClick={clicouWhatsapp}
        />
        
    </div>
}

function OpcaoPadrao({
    label,
    icone,
    onClick
}){


    return <ButtonToolbar>
        <OverlayTrigger
            placement='top'
            overlay={<Tooltip>{label}</Tooltip>}
        >
            <button
                onClick={onClick}
            >
                <FontAwesomeIcon className={styles.icone} icon={icone} />
            </button>
        </OverlayTrigger>
    </ButtonToolbar>
}



export default memo(Opcoes);