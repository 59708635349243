import { 
    useEffect, 
    useState 
} from "react";
import { 
    Badge, 
    Form,
    Button 
} from 'react-bootstrap';
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import moment from "moment";

import { converterReal } from "./../../../../Funcoes";
import Carregador from './../../../../Painel/Componentes/Carregador';
import './index.scss';

export default function ModalBoletos({
    cliente,
    loginViaPainel,
    mostrarModalBoletos,
    consultarCartaOferta,
    alterarMostrarModalBoletos
}) {

    const [carregando, alterarCarregando] = useState(false);
    const [salvando, alterarSalvando] = useState(false);
    const [registros, alterarRegistros] = useState([]);
    const [valorTotal, alterarValorTotal] = useState(0);
    const [possuiSelecionado, alterarPossuiSelecionado] = useState(false);
    const [selecionarTodos, alterarSelecionarTodos] = useState(true);

    // função para carregar eventos do mês que estão fechados
    async function carregarEventosFechadosDoMes() {

        // mostra carregamento
        alterarCarregando(true);

        try {

            // prepara parametros
            let parametros = {
                data: moment().format('YYYY-MM-DD')
            }

            // se estiver logando via painel
            if(loginViaPainel) {

                // resgata dados de login do usuario
                let dadosUsuarioRoca = window?.localStorage?.loginRoca ?? null;

                // caso encontrou login do roca
                if(dadosUsuarioRoca) {         
                    
                    // converte em objeto
                    dadosUsuarioRoca = JSON.parse(dadosUsuarioRoca);

                    // resgata token
                    parametros['tokenUsuario'] = dadosUsuarioRoca?.dadosUsuario?.token ?? null;
                }
            }

            // consulta eventos finalizados e sem boleto
            const { data } = await axios.get('/seguroAventura/eventosCartaOferta/finalizado', {
                params: {
                    ...parametros
                }
            });

            // prepara valor
            let valor = 0;
            
            // percorre registros adicionando o valor "selecionado" e alterando o estado
            alterarRegistros(data.registros ? data.registros
                .map((r) => {

                    // soma o valor
                    valor += parseInt(r.diarias.periodo) * parseFloat(cliente.seguroValorDiaria);

                    // retorna objeto
                    return {
                        ...r,
                        selecionado: true
                    }

                })
            : []);

            // verifica e altera estado
            alterarPossuiSelecionado((data.registros && data.registros.length > 0) ? true : false);
            
            // altera valor toal
            alterarValorTotal(valor < 9.90 ? 9.90 : valor);

        } catch(e) {
            console.error(e);
        } finally {
         
            // desativa carregamento
            alterarCarregando(false);

        }        

    }

    // função responsável por gerar boleto de eventos
    async function gerarBoletoEventos() {
        
        // insere carregamento
        alterarSalvando(true);

        // resgata eventos selecionados
        const eventosSelecionados = registros.filter((r) => r.selecionado);   
        
        // se não houver eventos selecionados, para de carregar
        if(eventosSelecionados.length === 0) return alterarSalvando(false);

        // prepara parametros
        const params = {
            idCliente: cliente.id,
            eventos: eventosSelecionados,
            valorBoleto: valorTotal
        };

        // se estiver logando via painel
        if(loginViaPainel) {

            // resgata dados de login do usuario
            let dadosUsuarioRoca = window?.localStorage?.loginRoca ?? null;

            // caso encontrou login do roca
            if(dadosUsuarioRoca) {         
                
                // converte em objeto
                dadosUsuarioRoca = JSON.parse(dadosUsuarioRoca);

                // resgata token
                params['tokenUsuario'] = dadosUsuarioRoca?.dadosUsuario?.token ?? null;
            }
        }

        try {            

            // faz a requisição para gerar o boleto do evento
            const { data } = await axios.post(`/seguroAventura/gerarBoletoDeEventos`, params);

            // abre url
            window.open(data);

            // consulta de novo
            carregarEventosFechadosDoMes();

        } catch(e) {

            console.error(e);

            // verifica se veio retorno
            if (e.response && e.response.data) {

                const erros = e.response.data;

                // resgata as chaves do objeto e percorre
                Object.keys(erros).map((erro) => {

                    // cria toast
                    return toast(({ closeToast }) => <>
                        <div className="toast-header bg-danger text-white">
                            <strong className="mr-auto">Erro na Geração do Boleto!</strong>
                            <button
                                onClick={closeToast}
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="toast-body  bg-danger text-white">
                            {erros[erro]}
                        </div>
                    </>, { autoClose: 5000 });

                });

            }

        } finally {

            // para carregamento
            alterarSalvando(false);

            // consulta registros
            consultarCartaOferta();

            // fecha modal
            alterarMostrarModalBoletos(false);            

        }

    }

    // primeira letra maiúscula
    function capitalizePrimeiraLetra(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // função para atualizar o valor do boleto
    function atualizarValorTotal() {

        // prepara o valor
        let valor = 0;
        let temSelecionado = false;

        // percorre os registros
        registros.forEach((r) => {

            // verifica se registro está selecionado
            if(r.selecionado) {
                valor += parseInt(r.diarias.periodo) * parseFloat(cliente.seguroValorDiaria);
                if(!temSelecionado) temSelecionado = true;
            }

        });

        // altera estado com variável booleana para verifica se há registro selecionado
        alterarPossuiSelecionado(temSelecionado);

        // altera o valor total
        alterarValorTotal(valor < 9.90 ? 9.90 : valor);

    }

    // função para selecionar ou desselecionar todos os eventos
    function verificarSelecionadorEventosTodos(seleciona) {

        // percorre registros e altera seleção de acordo com o parametro
        const novosRegistros = registros.map((r) => {
            return {
                ...r,
                selecionado: seleciona
            }
        });

        // altera estado
        alterarRegistros(novosRegistros)

    }

    useEffect(() => {
        carregarEventosFechadosDoMes();
    }, [])

    return (
        <Modal            
            show={mostrarModalBoletos}            
            size={'xl'}
            centered
            onExited={() => alterarMostrarModalBoletos(false)}
            onHide={() => alterarMostrarModalBoletos(false)}
        >
            <Modal.Header className='border-0 text-center'>
                <div className={'w-100 d-flex align-items-center titulo'}>
                    <Modal.Title className={['flex-grow-1 m-0 text-center'].join(' ')}>
                        <FontAwesomeIcon icon={faBarcode} className="mx-2"/>
                        Gerar Boleto de Eventos
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body className="pt-0 pb-0">
                <table className="table table-hover tabela mb-0">
                    <thead>
                        <tr>
                            <th 
                                className='estreito text-center'
                            >
                                {(registros.length > 0) ? <Form.Check
                                    type='checkbox'
                                    onChange={(e) => {
                                        // altera seleção de registros
                                        verificarSelecionadorEventosTodos(!selecionarTodos);

                                        // altera estado
                                        alterarSelecionarTodos(!selecionarTodos);

                                        // muda valor total
                                        atualizarValorTotal();
                                    }}
                                    checked={selecionarTodos}
                                /> : <></>}
                            </th>
                            <th className="text-center"><FontAwesomeIcon icon={faUsers} /></th>
                            <th>Evento</th>
                            <th>Local</th>
                            <th>Data início</th>
                            <th>Data fim</th>
                            <th>{capitalizePrimeiraLetra(moment().format("MMMM"))}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(carregando) && <Carregador className="rounded-bottom p-5" />}                        
                        {registros.length > 0 && registros.map(((registro, index) => {
                            return <tr
                                key={index}
                            >
                                <td 
                                    className='estreito text-center'
                                >
                                    <Form.Check
                                        id={registro.dados.id}
                                        type='checkbox'
                                        onChange={(e) => {
                                            // resgata registros
                                            const novoRegistros = [...registros];

                                            // altera seleção
                                            novoRegistros[index].selecionado = !novoRegistros[index].selecionado;

                                            // altera estado de registros
                                            alterarRegistros(novoRegistros);

                                            // atualiza o valor total dos eventos selecionados
                                            atualizarValorTotal();
                                        }}
                                        checked={registro.selecionado ?? false}
                                    />
                                </td>
                                <td className='estreito text-center'>
                                    <Badge variant={registro.dados.cancelado === 'S' ? 'danger' : (registro.dados.finalizado === 'S' ? 'success' : 'warning') }>{registro.dados.quantidadeParticipantes}</Badge>
                                </td>
                                <td>
                                    {registro.dados.nomeEvento}
                                </td>
                                <td>
                                    {registro.dados.localEvento}
                                </td>
                                <td>
                                    {registro.dados.dataInicio ? moment(registro.dados.dataInicio).format('DD/MM/YYYY') : ' - '}
                                </td>
                                <td>
                                    {registro.dados.dataFinal ? moment(registro.dados.dataFinal).format('DD/MM/YYYY') : ' - '}
                                </td>
                                <td>
                                    {(registro.diarias && parseInt(registro.diarias.periodo) > 0) && 
                                        <>
                                            <Badge variant="success">
                                                <span>R$ </span> 
                                                {converterReal(parseInt(registro.diarias.periodo) * parseFloat(cliente.seguroValorDiaria))}
                                            </Badge>
                                        </>
                                    }
                                </td>
                            </tr>

                        }))}     
                        <tr className="valor-total">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="text-right label">
                                <b>Valor Total:</b>
                            </td>
                            <td className="text-left valor">
                                <b>
                                    R$ {converterReal(valorTotal)}
                                </b>
                            </td>
                        </tr>                   
                    </tbody> 
                </table>
            </Modal.Body>
            <Modal.Footer className="rodape">
                <Button 
                    className='ml-1' 
                    variant="danger" 
                    onClick={() => alterarMostrarModalBoletos(false)} 
                    size="lg"
                >
                    <span>Cancelar</span>
                </Button>
                {(registros.length > 0 && possuiSelecionado) && <Button 
                    className={'mr-1'} 
                    variant={"padrao-azul"} 
                    disabled={salvando} 
                    onClick={() => gerarBoletoEventos()} 
                    size={"lg"}
                >
                    {salvando ?
                        <>
                            <FontAwesomeIcon 
                                className="icone" 
                                pulse 
                                icon={["fas", 'spinner']} 
                            />
                            <span>Salvando</span>
                        </> :
                        <span>Gerar boleto</span>
                    }
                </Button>}
            </Modal.Footer>
        </Modal>
    )

}