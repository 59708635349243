import React from 'react';
import './PainelSidebar.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imagemSidebar from './../../../assets/sidebar-6.jpg';
import { Badge } from 'react-bootstrap';

// componente
function PainelSidebar({
    ...props
}){

    // propriedades
    return <div className={["painel-sidebar", props.className].join(' ')}>
        <div className="itens">
            <div className="logo">
                <button onClick={props.encolherMenu}>
                    <FontAwesomeIcon className="icone" size="2x" icon={["fas", 'list-ul']} />
                </button>
                <Link to='/painel' className="texto-simples">{props.logo}</Link>
            </div>
            <ul className="nav">
                {props.links.filter(f => {
                    if(f.permitido === undefined){
                        return f;
                    }

                    if(f.permitido){
                        return f;
                    }
                }).map((link, a) => (
                    <li key={a} className="nav-item">
                        <Link to={link.url} className="nav-link">
                            <FontAwesomeIcon className="icone" icon={[link?.fab ? "fab" : "fas", link.icone]} />
                            <p>{link.descricao}</p>
                            { link.formulariosNaoLidos > 0 &&
                                <Badge
                                    variant='danger'
                                    className={'ml-2 p-1'}
                                >
                                    {link.formulariosNaoLidos}
                                </Badge>
                            }
                        </Link >
                    </li>
                ))}
            </ul>
        </div>
        <div 
            className="itens-fundo" 
            style={{
                backgroundImage: `url(${imagemSidebar})`
            }}
        ></div>
    </div>
}

// retorno
export default PainelSidebar;