import React, { 
    useRef,
    useState, 
    useEffect, 
} from 'react';
import { 
    useSelector, 
    useDispatch 
} from 'react-redux'
import { 
    Route, 
    useLocation 
} from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import publicIp from 'react-public-ip';

// importa sidebar componente
import PainelSidebar from './PainelSidebar';
import PainelNavbar from './PainelNavbar';
import './PainelPagina.scss';

// paginas
// import ClientesPaginas from './clientes/ClientesPagina';

// import ClienteCadastrarPagina from './clientes/ClienteCadastroPagina';
import ClientesConsultar from './Clientes/Consultar';
import ClientesCadastrar from './Clientes/Cadastrar';
import UsuariosConsultar from './Usuarios/Consultar';
import UsuariosPermissoes from './Usuarios/Permissoes';
import UsuariosTransferir from './Usuarios/Transferir';
import UsuariosIp from './Usuarios/Ip';
import ConfiguracaoSistema from './ConfiguracaoSistema';
import PainelInicio from './PainelInicio';
import ClienteEscreverEmail from './Clientes/EscreverEmail';
import CaixaSaidaConsultar from './CaixaSaida/Consultar';
import ModelosEmailConsultar from './ModelosEmail/Consultar';
import ModelosEmailCadastrar from './ModelosEmail/Cadastrar';
import EventosConsultar from './Eventos/Consultar';
import DispositivosConsultar from './Dispositivos/Consultar';
import DispositivosCadastrar from './Dispositivos/Cadastrar';
import GruposConsultar from './Grupos/Consultar';
import GruposCadastrar from './Grupos/Cadastrar';
import EventosPadraoConsultar from './EventosPadrao/Consultar';
import EventosPadraoCadastrar from './EventosPadrao/Cadastrar';
import PontoConsultar from './Ponto/Consultar';
import Relatorios from './Relatorios';
import ClientesEmailsMassa from './Clientes/EmailsMassa';
import FormularioAcientesPropostaConsultar from './FormularioAcientesProposta/Consultar';
import FormularioAcientesPropostaCadastrar from './FormularioAcientesProposta/Cadastrar';
import FormularioAcientesPropostaConsultarAgrupado from './FormularioAcientesProposta/ConsultarAgrupado';
import ClientesMapa from './ClientesMapa';
import ClientesEnviarSms from './Clientes/EnviarSms';
import EnviarWhatsapp from './Clientes/EnviarWhatsapp';
import SmsConsultar from './Sms/Consultar';
import ClientesEnviarSmsMassa from './Clientes/EnviarSmsMassa';
import ClientesWhatsappMassa from './Clientes/EnviarWhatsappMassa';
import ModelosSmsCadastrar from './ModelosSms/Cadastrar';
import ModelosSmsConsultar from './ModelosSms/Consultar';
import ModeloWhatsappConsultar from './ModelosWhatsapp/Consultar';
import ModeloWhatsappCadastrar from './ModelosWhatsapp/Cadastrar';
import SolicitacaoPagamentoConsultar from './SolicitacaoPagamento/Consultar';
import SolicitacaoPagamentoCadastrar from './SolicitacaoPagamento/Cadastrar';
import HistoricoConsultar from './Historico/Consultar';
import FichasCadastrar from './Fichas/Cadastrar';
import SeguroAventuraConsultar from './SeguroAventura/Consultar';
import SeguroAventuraFechamento from './SeguroAventura/Fechamento';
import MensagensWhatsapp from './MensagensWhatsapp';
import ClientesEventosMapa from './SeguroAventura/Consultar/ClientesEventosMapa';
import RelatorioInadimplencia from './RelatorioInadimplencia/Consultar';

// componente
export default function PainelPagina(){

    // estados
    const location = useLocation();
    const login = useSelector(state => state.login);
    const dispatch = useDispatch();
    const verificar = useRef(true);
    
    const [menuEncolhido, encolherMenu] = useState(false);
    const [telaMapas, alterarTelaMapas] = useState(false);
    const [liberar, alterarLiberar] = useState(false);
    
    async function preCarregar(){

        // carrega ipv4
        const ipv4 = await publicIp.v4() || null;

        // define token
        axios.defaults.headers.common['tokenusuario'] = login.dadosUsuario.token;
        axios.defaults.headers.common['ipusuario'] = ipv4;
        
        // mantém a pessoa logada pelas próximas 15 horas
        if(login.horaLogin){
            let dataAtual = new Date();
            let horas = moment.duration(moment(dataAtual).diff(login.horaLogin)).hours();

            // se desde o último acesso passou 1 minuto, desloga
            if(horas >= 10){
                dispatch({
                    type: 'FAZER_LOGGOF',
                    reducer: 'login'
                });
                verificar.current = false;
            }else{

                // atualiza tempo
                dispatch({
                    type: 'ATUALIZAR_TEMPO_LOGADO',
                    reducer: 'login'
                });
            }
        }

        // se componente estiver montado
        if(verificar.current){


            // carrega as configurações iniciais
            await carregarConfiguracaoInicial();
        }


        // libera
        alterarLiberar(true);
    }

    async function carregarConfiguracaoInicial(){

        try {

            // faz a requisição
            const { data } = await axios.get(`/usuariosPermissao/${login.dadosUsuario.id}`);

            // (estes "data.registros" vem como objeto e não como array)
            
            // atualiza configurações no redux
            dispatch({
                type: 'painelReducer/CONFIGURACOES_SISTEMA',
                configuracoesSistema: data.registros
            });

            // atualiza permissões no redux
            dispatch({
                type: 'ATUALIZAR_PERMISSAO',
                permissao: data.registros
            });

        } catch({ response }){
            console.log(response);
        }

        try {

            // faz a requisição
            const { data } = await axios.get(`/configuracaoSistema`);
            dispatch({
                type: 'painelReducer/CONFIGURACOES_SISTEMA',
                configuracoesSistema: data.chaves
            });


        } catch({ response }){
            console.log(response);
        }

        try {

            // faz a requisição
            const { data } = await axios.get(`/dispositivos/verificar`);
            dispatch({
                type: 'painelReducer/DISPOSITIVO',
                dispositivo: data.dispositivo,
                ponto: data.ponto
            });

        }catch({ response }){
            console.log(response);
        }
    }

    // rotas
    const rotas = [

        // base
        {path: '/painel', component: PainelInicio},

        // Relatorios
        {path: '/painel/relatorios', component: Relatorios},

        // dispositivos 
        {path: '/painel/dispositivos', component: DispositivosConsultar, permitido: (parseInt(login.permissao.dispositivos) > 1)},
        {path: '/painel/dispositivos/cadastrar', component: DispositivosCadastrar, permitido: (parseInt(login.permissao.dispositivos) > 2)},
        {path: '/painel/dispositivos/alterar/:id', component: DispositivosCadastrar, permitido: (parseInt(login.permissao.dispositivos) > 1)},
        
        // clientes
        {path: '/painel/clientes', component: ClientesConsultar, permitido: (parseInt(login.permissao.clientes) > 1)},
        {path: '/painel/clientes/cadastrar', component: ClientesCadastrar, permitido: (parseInt(login.permissao.clientes) > 2)},
        {path: '/painel/clientes/alterar/:id', component: ClientesCadastrar, permitido: (parseInt(login.permissao.clientes) > 1)},
        {path: '/painel/clientes/enviar-email/:idcliente', component: ClienteEscreverEmail, permitido: (login.permissao.clienteEnviarEmail === 'S')},
        {path: '/painel/clientes/abrir-email/:id', component: ClienteEscreverEmail, permitido: (login.permissao.clienteEnviarEmail === 'S')},
        {path: '/painel/clientes/enviar-email-aniversariante/:idcliente/:idade', render: (rotaProps) => <ClienteEscreverEmail {...rotaProps} aniversariante='S' /> },
        {path: '/painel/clientes/emails-massa', component: ClientesEmailsMassa},
        {path: '/painel/clientes/enviar-sms/:idcliente', component: ClientesEnviarSms},
        {path: '/painel/clientes/enviar-sms-aniversariante/:idcliente/:idade', render: (rotaProps) => <ClientesEnviarSms {...rotaProps} aniversariante='S' /> },
        {path: '/painel/clientes/enviar-sms-massa', component: ClientesEnviarSmsMassa},
        {path: '/painel/clientes/enviar-whatsapp-massa', component: ClientesWhatsappMassa},
        {path: '/painel/clientes/enviar-whatsapp/:idcliente', component: EnviarWhatsapp},
        
        // grupos 
        {path: '/painel/grupos', component: GruposConsultar, permitido: login.permissao?.habilitarAcessoGrupoClientes === "S"},
        {path: '/painel/grupos/cadastrar', component: GruposCadastrar, permitido: login.permissao?.habilitarAcessoGrupoClientes === "S"},
        {path: '/painel/grupos/alterar/:id', component: GruposCadastrar, permitido: login.permissao?.habilitarAcessoGrupoClientes === "S"},

        // eventos
        {path: '/painel/eventos', component: EventosConsultar, permitido: login.permissao?.habilitarAcessoEventos === "S"},

        // eventos automáticos ou padrão 
        {path: '/painel/eventos-automaticos', component: EventosPadraoConsultar, permitido: login.permissao?.habilitarAcessoEventos === "S"},
        {path: '/painel/eventos-automaticos/cadastrar', component: EventosPadraoCadastrar, permitido: login.permissao?.habilitarAcessoEventos === "S"},
        {path: '/painel/eventos-automaticos/alterar/:id', component: EventosPadraoCadastrar, permitido: login.permissao?.habilitarAcessoEventos === "S"},

        // fichas
        {path: '/painel/clientes/alterar/:id/fichas', render: (rotaProps) => <ClientesCadastrar {...rotaProps} abaAbrir='fichas' /> },
        {path: '/painel/clientes/alterar/:id/apolices', render: (rotaProps) => <ClientesCadastrar {...rotaProps} abaAbrir='apolices' /> },

        // usuários
        {path: '/painel/usuarios', component: UsuariosConsultar, permitido: (parseInt(login.permissao.usuarios) > 1)},
        {path: '/painel/usuarios/cadastrar', render: (rotaprops) => <ClientesCadastrar {...rotaprops} usuario={true} />, permitido: (parseInt(login.permissao.usuarios) > 2)},
        {path: '/painel/usuarios/alterar/:id', render: (rotaprops) => <ClientesCadastrar {...rotaprops} usuario={true} />, permitido: (parseInt(login.permissao.usuarios) > 1)},
        {path: '/painel/usuarios/transferir/:id', component: UsuariosTransferir, permitido: (parseInt(login.permissao.usuarios) > 1)},
        {path: '/painel/usuarios/ip/:id', component: UsuariosIp, permitido: (parseInt(login.permissao.usuarios) > 1)},
        {path: '/painel/usuarios/permissoes/:id', component: UsuariosPermissoes, permitido: (login.permissao.usuariosPermissao === 'S')},

        // ponto 
        {path: '/painel/ponto/:id', component: PontoConsultar, permitido: (login.permissao.habilitarPontoConsultaUsuario === 'S')}, // não ter acesso quando não tiver permissão
        {path: '/painel/meu-ponto', component: PontoConsultar},

        // caixa de saida
        {path: '/painel/caixa-saida', component: CaixaSaidaConsultar, permitido: login.permissao?.habilitarAcessoCaixaDeSaida === "S"},

        // sms
        {path: '/painel/sms', component: SmsConsultar, permitido: login.permissao?.habilitarAcessoMensagens === "S"},

        // modelos de email
        {path: '/painel/modelos-email', component: ModelosEmailConsultar},
        {path: '/painel/modelos-email/cadastrar', component: ModelosEmailCadastrar},
        {path: '/painel/modelos-email/alterar/:id', component: ModelosEmailCadastrar},

        // modelos de sms
        {path: '/painel/modelos-sms', component: ModelosSmsConsultar},
        {path: '/painel/modelos-sms/cadastrar', component: ModelosSmsCadastrar},
        {path: '/painel/modelos-sms/alterar/:id', component: ModelosSmsCadastrar},

        // modelos de whatsapp
        {path: '/painel/modelos-whatsapp', component: ModeloWhatsappConsultar},
        {path: '/painel/modelos-whatsapp/cadastrar', component: ModeloWhatsappCadastrar},
        {path: '/painel/modelos-whatsapp/alterar/:id', component: ModeloWhatsappCadastrar},

        // solicitação de formulários
        // {path: '/painel/solicitacao-formulario', component: SolicitacaoFormularioConsultar, permitido: login.permissao?.habilitarSolicitacaoFianca === "S"},
        // {path: '/painel/solicitacao-formulario/cadastrar', component: SolicitacaoFormularioCadastrar, permitido: login.permissao?.habilitarSolicitacaoFianca === "S"},
        // {path: '/painel/solicitacao-formulario/alterar/:id', component: SolicitacaoFormularioCadastrar, permitido: login.permissao?.habilitarSolicitacaoFianca === "S"},

        // formularios de acidentes pessoais
        {path: '/painel/proposta-formularios', component: FormularioAcientesPropostaConsultar},
        {path: '/painel/proposta-formularios/agrupado', component: FormularioAcientesPropostaConsultarAgrupado},
        {path: '/painel/proposta-formularios/alterar/:id', component: FormularioAcientesPropostaCadastrar},

        // configuração sistema
        {path: '/painel/configuracoes', component: ConfiguracaoSistema},

        // mapa dos clientes
        {path: '/painel/clientes-mapa', component: ClientesMapa},

        // solicitação de pagamentos
        {path: '/painel/solicitacao-pagamento', component: SolicitacaoPagamentoConsultar, permitido: login.permissao?.habilitarAcessoSolicitacaoPagamento === "S"},
        {path: '/painel/solicitacao-pagamento/alterar/:id', component: SolicitacaoPagamentoCadastrar, permitido: login.permissao?.habilitarAcessoSolicitacaoPagamento === "S"},

        // histórico, logde funções executadas
        {path: '/painel/historico', component: HistoricoConsultar},  

        // carrega mensagens do whatsapp enviadas aos usuários
        {path: '/painel/mensagens-whatsapp', component: MensagensWhatsapp, permitido: login.permissao?.habilitarAcessoMensagensWhatsapp === "S"},

        // carrega relatorio de clientes inadimplentes e seus grupos correspondentes
        {path: '/painel/relatorio-inadimplencia', component: RelatorioInadimplencia, permitido: login.permissao?.habilitarAcessoRelatorioInadimplencia === "S"},

        // backups
        // {path: '/painel/backups', component: BackupsConsultar, permitido: login.permissao.habilitarConsultaBackups === "S"}
    ];

    // inicializa
    useEffect(() => {
        preCarregar();
    }, []);

    // quando a rota muda
    useEffect(() => {
        if(
            location.pathname === '/painel/clientes-mapa' || 
            location.pathname === '/painel/seguro-aventura/clientes-eventos-mapa'
        ) {
            encolherMenu(true);
            alterarTelaMapas(true);
        } else if(location.pathname === '/painel/relatorio-inadimplencia') {
            encolherMenu(true);
            alterarTelaMapas(false);
        } else {
            alterarTelaMapas(false);
        }
    }, [location.pathname]);
    
    // inicializado corretamente
    if(!liberar){
        return <></>
    }
    
    return <div className={['painel-pagina', (menuEncolhido)? 'menu-encolher' : ''].join(' ')}>
        <PainelSidebar logo='Roca Seguros' className={(menuEncolhido)? 'encolher' : ''} encolherMenu={() => encolherMenu(!menuEncolhido)} links={[
            {icone: 'user-friends', descricao: 'Usuários', url: '/painel/usuarios', permitido: (parseInt(login.permissao.usuarios) > 1)},
            {icone: 'desktop', descricao: 'Dispositivos', url: '/painel/dispositivos', permitido: (parseInt(login.permissao.dispositivos) > 1)},
            {icone: 'users', descricao: 'Clientes', url: '/painel/clientes', permitido: (parseInt(login.permissao.clientes) > 1)},
            {icone: 'city', descricao: 'Grupos de Clientes', url: '/painel/grupos', permitido: login.permissao?.habilitarAcessoGrupoClientes === "S"},
            {icone: 'thumbtack', descricao: 'Eventos', url: '/painel/eventos', permitido: login.permissao?.habilitarAcessoEventos === "S"},
            {icone: 'mountain', descricao: 'Seguro Aventura', url: '/painel/seguro-aventura', formulariosNaoLidos: 0, permitido: login.permissao?.habilitarAcessoSeguroAventura === "S"},
            {icone: 'envelope', descricao: 'Caixa de Saída', url: '/painel/caixa-saida', permitido: login.permissao?.habilitarAcessoCaixaDeSaida === "S"},
            {icone: 'mobile-alt', descricao: 'Mensagens', url: '/painel/sms', permitido: login.permissao?.habilitarAcessoMensagens === "S"},
            {icone: 'credit-card', descricao: 'Solicitação Pagamento', url: '/painel/solicitacao-pagamento', permitido: login.permissao?.habilitarAcessoSolicitacaoPagamento === "S"},
            {icone: 'whatsapp', fab: true, descricao: "Mensagens WhatsApp", url: '/painel/mensagens-whatsapp', permitido: login.permissao?.habilitarAcessoMensagensWhatsapp === "S"},
            {icone: 'clipboard', descricao: 'Relatório Inadimplência', url: '/painel/relatorio-inadimplencia', permitido: login.permissao?.habilitarAcessoRelatorioInadimplencia === "S"}
        ]} />
        <div className={["painel-pagina-corpo", telaMapas ? 'tela-mapas' : ''].join(' ')}>
            <PainelNavbar />
            <div className="content">
                <div className="container-fluid">
                    {rotas.filter((f) => {
                        if(f?.permitido === undefined || f.permitido){
                            return f;
                        }
                    }).map((rota, a) => (
                        <Route 
                            key={`${rota.url + '_' + a}`} 
                            exact 
                            {...rota} 
                        />
                    ))}

                    {/* rotas */}
                    {(parseInt(login.permissao.clientes) > 1) && <Route exact path={[`/painel/fichas/:id_cliente/cadastrar`, `/painel/fichas/:id_cliente/alterar/:id`, `/painel/fichas/:id_cliente/renovar/:id_ficha_renovar`]}>
                        <FichasCadastrar />
                    </Route>}

                    {login.permissao?.habilitarAcessoSeguroAventura === "S" && <Route exact path={[`/painel/seguro-aventura`]}>
                        <SeguroAventuraConsultar />
                    </Route>}

                    {login.permissao?.habilitarAcessoSeguroAventura === "S" && <Route exact path={['/painel/seguro-aventura/clientes-eventos-mapa']}>
                        {(props) => {
                            // caso nao tiver chave ou não estiver na rota
                            if(
                                !props?.location?.state?.chaveApi || 
                                location.pathname !== '/painel/seguro-aventura/clientes-eventos-mapa'
                            ) return <></>

                            // retorna mapa com clientes
                            return <ClientesEventosMapa 
                                {...props}
                            />
                        }}
                    </Route>}

                    {login.permissao?.habilitarAcessoSeguroAventura === "S" && <Route exact path={[`/painel/seguro-aventura/fechamento/:dataMes`]}>
                        <SeguroAventuraFechamento />
                    </Route>}

                </div>
            </div>
        </div>
    </div>;

}
