import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

// // configura padrões axios // verifica rotas na api
// axios.defaults.baseURL = `${process.env.REACT_APP_URL_PHP}`;
// axios.defaults.headers.post['Content-Type'] = 'application/json';
// // axios.defaults.headers.common['tokenCliente'] = 'token aqui';

axios.interceptors.response.use(
function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log(response);
    return response;
}, 
function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log(error);
    
    // erro no servidor
    if(error.response){

        // se for erro de autorização invalida
        if(error.response.status === 401){
            
            // caso esteja fazendo o login via 2 fatores
            if(!error.response?.data['DUAS_ETAPAS']) {

                // token inválido, usuário precisa relogar
                toast(({closeToast }) => <>
                    <div className="toast-header bg-danger text-white">
                        <strong className="mr-auto">Acesso negado!</strong>
                        <button 
                            onClick={closeToast} 
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="toast-body  bg-danger text-white">
                        Você precisa refazer login
                    </div>
                </>);

            }

        }
        
        // erro de ip não habilitado
        if(error.response.status === 403){
            
            // token inválido, usuário precisa relogar
            // window.alert('Você não possui acesso a partir deste terminal!');
            toast(({ closeToast }) => <>
                <div className="toast-header bg-danger text-white">
                    <strong className="mr-auto">Você precisa refazer login!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body  bg-danger text-white">
                    Você não possui acesso a partir deste terminal!
                </div>
            </>);

            // remove login do cache
            window.localStorage.removeItem('loginRoca');

            // resgata url atual
            const urlAtual = window.location.href;
            
            // resgata as 3 primeiras posições
            const [zero, um, dois] = urlAtual.split('/');
            
            // redireciona para a primeira rota
            window.location.replace([zero, um, dois].join('/'));

        }

        // se for erro de autorização invalida
        if(error.response.status === 500){

            // token inválido, usuário precisa relogar
            window.alert('Erro na requisição, contate o suporte!');

        }

        // erros relacionados ao banco
        if(error.response.status === 422){

            // token inválido, usuário precisa relogar
            window.alert('Requisição inválida!');
            console.error(error.response.data);

        }

        // erros relacionados ao banco
        if(error.response.status === 402){

            // token inválido, usuário precisa relogar
            // window.alert('Seu acesso expirou!');
            console.error(error.response.data);

        }



    }else if(error.request){

        // erro na requisição
        window.alert('Erro de conexão!');

    }else{
        window.alert('Erro na solicitação');
    }

    // promessa rejeitada
    return Promise.reject(error);
});