import React, { 
    useState, 
    useEffect 
} from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import publicIp from 'react-public-ip';
import axios from 'axios';

import './LoginPagina.scss';

// pagina login
export default function LoginPagina(){

    // estados
    const [carregar, alterarCarregando] = useState(false);
    const [erro, mostrarErro] = useState({
        campo: '',
        mensagem: ''
    });
    const [erros, alterarErros] = useState({});
    const [duasEtapas, alterarDuasEtapas] = useState({
        mostrar: false,
        codigo: ''
    })
    const dispatch = useDispatch();

    // define dados do formulário
    const [dados, updateDados] = useState({
        login: '',
        senha: '',
        ip: null,
        lembrar: false
    });    

    // busca ip, se não encontrar apresenta erro
    async function carregarIp(){
        const ipv4 = await publicIp.v4() || null;
        dados.ip = ipv4;
        updateDados({...dados});
        if(ipv4 === null){
            alert('login indisponivel, tente mais tarde!');
        }
    }

    // chama api para efetuar o login
    async function clicouEntrar() {

        alterarCarregando(true);
        alterarErros({});

        try {

            // faz a requisição
            const { data } = await axios.post(`/login`, dados);
            
            // se for para lembrar então esquece o horario
            let lembrar = new Date();
            if(dados.lembrar){
                lembrar = null;
            }

            // pré define configurações default
            dispatch({
                type: 'painelReducer/CONFIGURACOES_SISTEMA',
                configuracoesSistema: data.configuracaoSistema
            });

            // altera os dados do usuário
            dispatch({
                type: 'FAZER_LOGIN',
                reducer: 'login',
                dadosUsuario: data.dadosUsuario,
                dadosCliente: data.dadosCliente,
                permissao: data.permissao,
                horaLogin: lembrar
            });

        } catch({ response }) {
            if(response){
                if(response.status === 400){
                    alterarErros(response.data);
                }
                
                // caso login não foi autorizado
                if(response.status === 401 && response.data['DUAS_ETAPAS']) {

                    // mostra campo para adicionar o codigo
                    alterarDuasEtapas({ 
                        ...duasEtapas, 
                        mostrar: true
                    });

                    // mensagem de erro
                    toast(<>
                        <div
                            style={{ background: '#ff6271' }}
                        >
                            <div className="toast-header">
                                <strong className="mr-auto">Autenticação de 2 fatores necessária!</strong>
                                <button
                                    className="ml-2 mb-1 close btn-outline-light outline-0"
                                >
                                </button>
                            </div>
                            <div className="toast-body text-light">
                                <p>{response.data['DUAS_ETAPAS']}</p>                            
                            </div>
                        </div>
                    </>);
                }
            }
        } finally {
            alterarCarregando(false);
        }

    }

    // envia codigo de verificação de login para a api
    async function enviarCodigoVerificacao() {

        // mostra carregamento e remove erros
        alterarCarregando(true);
        alterarErros({});

        // resgata informações
        const { codigo } = duasEtapas;

        try {

            // chama api para ativar o login
            await axios.post('/usuariosLogin/codigo', {
                codigo
            });

            // zera estado
            alterarDuasEtapas({});

            // chama a função de login novamente
            clicouEntrar();

        } catch({ response }) {
            // mensagem de erro
            toast(<>
                <div
                    style={{ background: '#ff6271' }}
                >
                    <div className="toast-header">
                        <strong className="mr-auto">Erro!</strong>
                        <button
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                        </button>
                    </div>
                    <div className="toast-body text-light">
                        <p>O código inserido está incorreto</p>                            
                    </div>
                </div>
            </>);
        } finally {
            // desativa carregamento
            alterarCarregando(false);
        }

    }

    // inicializa
    useEffect(() => {
        carregarIp();
    }, []);

    // retorna componente
    return <div className="container-fluid login-pagina">
        <div className="row no-gutter">
            <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
            <div className="col-md-8 col-lg-6">
                <div className="login d-flex align-items-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-lg-8 mx-auto">
                            <h3 className="login-heading mb-4">Bem vindo!</h3>
                                {(!duasEtapas.mostrar) && <div className="form-label-group">
                                    <input 
                                        type="text" id="inputEmail"
                                        className={["form-control", erros.login ? 'input-erro' : ''].join(' ')} 
                                        placeholder="Usuário" 
                                        required autoFocus
                                        value={dados.login}
                                        onChange={(e) => {
                                            updateDados({
                                                ...dados,
                                                login: e.target.value
                                            });
                                        }}
                                        onKeyDown={e => {
                                            if(e.keyCode === 13) clicouEntrar(e);
                                        }}
                                    />
                                    <label htmlFor="inputEmail">Usuário</label>
                                    {erros.login && 
                                        <Alert className="alerta-erro" dismissible onClose={() => alterarErros({})} variant="danger">
                                            <p>{erros.login}</p>
                                        </Alert>
                                    }
                                    
                                </div>}

                                {(!duasEtapas.mostrar) && <div className="form-label-group">
                                    <input 
                                        id="inputConfirmPassword"
                                        type="password"
                                        className={["form-control", erros.senha && 'input-erro'].join(' ')}
                                        placeholder="Senha" required 
                                        value={dados.senha}
                                        onChange={(e) => {
                                            updateDados({
                                                ...dados,
                                                senha: e.target.value
                                            });
                                        }}
                                        onKeyDown={e => {
                                            if(e.keyCode === 13) clicouEntrar(e);
                                        }}
                                    />
                                    <label htmlFor="inputConfirmPassword">Senha</label>
                                    {erros.senha && 
                                        <Alert className="alerta-erro" dismissible onClose={() => alterarErros({})} variant="danger">
                                            <p>{erros.senha}</p>
                                        </Alert>
                                    }
                                </div>}

                                {(!duasEtapas.mostrar) && <div className="custom-control custom-checkbox mb-3">
                                    <input 
                                        type="checkbox" 
                                        className="custom-control-input" 
                                        id="customCheck1" 
                                        value={dados.lembrar}
                                        onChange={(e) => {dados.lembrar = e.target.value;}} 
                                    />
                                    <label className="custom-control-label" htmlFor="customCheck1">Lembrar</label>
                                </div>}

                                {(duasEtapas.mostrar) && <div className="form-label-group">
                                    <input 
                                        type="text"                                         
                                        id="inputCodigoVerificacao"
                                        className={["form-control"].join(' ')} 
                                        placeholder="Código de verificação" 
                                        required 
                                        autoFocus
                                        maxLength={6}
                                        value={duasEtapas.codigo}
                                        onChange={(e) => {

                                            // caso não for numero, não altera estado
                                            if(isNaN(parseFloat(e.target.value)) && e.target.value.length > 0) return;
                                            
                                            alterarDuasEtapas({
                                                ...duasEtapas,
                                                codigo: e.target.value
                                            });
                                        }}
                                        onKeyDown={e => {
                                            if(e.keyCode === 13) enviarCodigoVerificacao(e);
                                        }}
                                    />
                                    <label htmlFor="inputCodigoVerificacao">Código de verificação</label>
                                </div>}

                                <button 
                                    onClick={() => duasEtapas?.mostrar ? enviarCodigoVerificacao() : clicouEntrar()} 
                                    className="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2" 
                                    type="submit"
                                    disabled={carregar || (dados.ip === null)}
                                >
                                {carregar ? 
                                   (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : ''
                                }
                                {carregar ? ' Carregando' : (duasEtapas?.mostrar ? "Enviar" : 'Entrar') }
                                </button>
                                {!duasEtapas?.mostrar && <div className="text-center">
                                    <Link className="small" to="lembrarSenha">Esqueceu a senha?</Link>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
