import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import { useParams } from 'react-router-dom';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';

export default function Permissoes(){

    // estados
    const { id } = useParams();
    const [carregando, alterarCarregando] = useState(true);
    const [salvando, alterarSalvando] = useState(false);
    const [dados, alterarDados] = useState({
        clienteAnexos: '1',
        clienteEnviarEmail: 'N',
        clienteVerRelatorioAtendimentos: 'N',
        clientes: '1',
        dispositivos: '1',
        habilitarCadastroLoginCliente: 'N',
        habilitarGerarPdfPonto: 'N',
        habilitarPontoConsultaUsuario: 'N',
        habilitaAcessoArquivoWhatsapp: 'N',
        habilitarSolicitacaoFianca: 'N',
        habilitarTemporariamenteDefinirDispositivo: 'N',
        habilitarAcessoGrupoClientes: "N",
        habilitarAcessoEventos: "N",
        habilitarAcessoSeguroAventura: "N",
        habilitarAcessoCaixaDeSaida: "N",
        habilitarAcessoMensagens: "N",
        habilitarAcessoSolicitacaoPagamento: "N",
        habilitarAcessoMensagensWhatsapp: "N",
        habilitarAcessoRelatorioInadimplencia: "N",
        habilitarAcessoMapaClientes: "N",
        habilitarAcessoRelatorios: "N",
        habilitarAcessoHistorico: "N",
        usuarios: '1',
        usuariosPermissao: 'N',
        habilitarLiberarIpUsuario: 'N'
    });    

    // consulta as chaves do usuários
    async function consultarChavesUsuario(){
        alterarCarregando(true);

        try {

            // faz a requisição
            const { data } = await axios.get(`/usuariosPermissao/${id}`);
            alterarDados(data.registros);

        } catch ({ response }){
            console.log(response);
        } finally {
            alterarCarregando(false);
        }
        
    }

    // salva
    async function salvar(){
        alterarSalvando(true);

        try {

            // faz a requisição
            await axios.post(`/usuariosPermissao`, {
                chaves: dados,
                id_usuario: id
            });

        } catch({ response }){
            console.log(response);
        } finally {
            alterarSalvando(false);
        }
    }

    // carrega as permissões do usuário
    useEffect(() => {
        consultarChavesUsuario();
    }, []);

    // carregando
    if(carregando){
        return <div>Carregando...</div>
    }

    // permissões
    return <>

        {/* USUÁRIOS */}
        <div className={[styles.categoria, 'mb-2'].join(' ')}>
            <FontAwesomeIcon className={styles.icone} icon={["fas", "user-friends"]} />
            <span>Usuários</span>
        </div>
        <Row className='mb-4'>
            <Col md='3'>
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                            Geral
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {[
                            {value: '1', label: 'Sem acesso'},
                            {value: '2', label: 'Consulta'},
                            {value: '3', label: 'Cadastro/Edição'},
                            {value: '4', label: 'Exclusão'},
                        ].map(permissao => 
                            <Form.Check
                                key={permissao.value} 
                                {...permissao}
                                type={'radio'}
                                checked={permissao.value === dados.usuarios}
                                id={`usuarios-${permissao.value}`}
                                onChange={e => {
                                    alterarDados({...dados, usuarios: e.target.value})
                                }}
                                className={styles.inputCheck}
                            />    
                        )}
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                            Opções
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Check
                            label='Habilita editar as Permissões do Usuário'
                            checked={dados.usuariosPermissao === 'S'}
                            id={`usuarios-permissao`}
                            onChange={e => {
                                dados.usuariosPermissao = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />                        
                        <Form.Check
                            label='Habilita gerar pdf dos pontos, inclusive o seu próprio'
                            checked={dados.habilitarGerarPdfPonto === 'S'}
                            id={`habilitar-gerar-pdf-ponto`}
                            onChange={e => {
                                dados.habilitarGerarPdfPonto = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Habilita liberar os ips'
                            checked={dados.habilitarLiberarIpUsuario === 'S'}
                            id={`habilitar-liberar-ip-usuario`}
                            onChange={e => {
                                dados.habilitarLiberarIpUsuario = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        {/* DISPOSITIVOS */}
        <div className={[styles.categoria, 'mb-2'].join(' ')}>
            <FontAwesomeIcon className={styles.icone} icon={["fas", "desktop"]} />
            <span>Dispositivos</span>
        </div>
        <Row className='mb-4'>
            <Col md='3'>    
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                        Geral
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {[
                            {value: '1', label: 'Sem acesso'},
                            {value: '2', label: 'Consulta'},
                            {value: '3', label: 'Cadastro/Edição'},
                            {value: '4', label: 'Exclusão'},
                        ].map(permissao => 
                            <Form.Check
                                key={permissao.value} 
                                {...permissao}
                                type={'radio'}
                                checked={permissao.value === dados.dispositivos}
                                id={`dispositivos-${permissao.value}`}
                                onChange={e => {alterarDados({...dados, dispositivos: e.target.value})}}
                                className={styles.inputCheck}
                            />    
                        )}
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                            Opções
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Check
                            label='Habilitar o usuário a definir o dispositivo temporariamente até que seja definido'
                            checked={dados.habilitarTemporariamenteDefinirDispositivo === 'S'}
                            id={`habilitar-temporariamente-definir-dispositivo`}
                            onChange={e => {
                                dados.habilitarTemporariamenteDefinirDispositivo = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        {/* CLIENTES */}
        <div className={[styles.categoria, 'mb-2'].join(' ')}>
            <FontAwesomeIcon className={styles.icone} icon={["fas", "users"]} />
            <span>Clientes</span>
        </div>
        <Row className='mb-4'>
            <Col md='3'>
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                            Geral
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {[
                            {value: '1', label: 'Sem acesso'},
                            {value: '2', label: 'Consulta'},
                            {value: '3', label: 'Cadastro/Edição'},
                            {value: '4', label: 'Exclusão'},
                        ].map(permissao => 
                            <Form.Check
                                key={permissao.value} 
                                {...permissao}
                                type={'radio'}
                                checked={permissao.value === dados.clientes}
                                id={`clientes-${permissao.value}`}
                                onChange={e => {alterarDados({...dados, clientes: e.target.value})}}
                                className={styles.inputCheck}
                            />    
                        )}
                    </Card.Body>
                </Card>     
            </Col>
            <Col md='3'>
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                        Anexos
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {[
                            {value: '1', label: 'Sem acesso'},
                            {value: '2', label: 'Consulta'},
                            {value: '3', label: 'Cadastro/Edição'},
                            {value: '4', label: 'Exclusão'},
                        ].map(permissao => 
                            <Form.Check
                                key={permissao.value} 
                                {...permissao}
                                type={'radio'}
                                checked={permissao.value === dados.clienteAnexos}
                                id={`clientes-anexos-${permissao.value}`}
                                onChange={e => {alterarDados({...dados, clienteAnexos: e.target.value})}}
                                className={styles.inputCheck}
                            />    
                        )}
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                            Opções
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Check
                            label='Enviar email'
                            checked={dados.clienteEnviarEmail === 'S'}
                            id={`cliente-enviar-email`}
                            onChange={e => {
                                dados.clienteEnviarEmail = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Ver relatório de atendimentos'
                            checked={dados.clienteVerRelatorioAtendimentos === 'S'}
                            id={`cliente-ver-relatorio-atendimentos`}
                            onChange={e => {
                                dados.clienteVerRelatorioAtendimentos = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Habilita cadastro de login e senha para cadastro/edição de cliente'
                            checked={dados.habilitarCadastroLoginCliente === 'S'}
                            id={`habilitar-cadastro-login-cliente`}
                            onChange={e => {
                                dados.habilitarCadastroLoginCliente = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />                   
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        {/* MENU LATERAL */}
        <div className={[styles.categoria, 'mb-2'].join(' ')}>
            <FontAwesomeIcon className={styles.icone} icon={["fas", "list-ul"]} />
            <span>Menu lateral</span>
        </div>
        <Row className='mb-4'>
            <Col>
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                            Opções
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Check
                            label='Habilitar acesso em Grupos De Clientes'
                            checked={dados.habilitarAcessoGrupoClientes === 'S'}
                            id={`habilitar-acesso-grupos-clientes`}
                            onChange={e => {
                                dados.habilitarAcessoGrupoClientes = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Habilitar acesso em Eventos'
                            checked={dados.habilitarAcessoEventos === 'S'}
                            id={`habilitar-acesso-eventos`}
                            onChange={e => {
                                dados.habilitarAcessoEventos = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Habilitar acesso em Seguro aventura'
                            checked={dados.habilitarAcessoSeguroAventura === 'S'}
                            id={`habilitar-acesso-seguro-aventura-painel`}
                            onChange={e => {
                                dados.habilitarAcessoSeguroAventura = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Habilitar acesso em Caixa De Saída'
                            checked={dados.habilitarAcessoCaixaDeSaida === 'S'}
                            id={`habilitar-acesso-caixa-de-saida`}
                            onChange={e => {
                                dados.habilitarAcessoCaixaDeSaida = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Habilitar acesso em Mensagens'
                            checked={dados.habilitarAcessoMensagens === 'S'}
                            id={`habilitar-acesso-mensagens`}
                            onChange={e => {
                                dados.habilitarAcessoMensagens = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Habilitar acesso em Solicitação Pagamento'
                            checked={dados.habilitarAcessoSolicitacaoPagamento === 'S'}
                            id={`habilitar-acesso-solicitacao-pagamento`}
                            onChange={e => {
                                dados.habilitarAcessoSolicitacaoPagamento = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Habilitar acesso em Mensagens Whatsapp'
                            checked={dados.habilitarAcessoMensagensWhatsapp === 'S'}
                            id={`habilitar-acesso-mensagens-whatsapp`}
                            onChange={e => {
                                dados.habilitarAcessoMensagensWhatsapp = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Habilitar acesso em Relatório Inadimplência'
                            checked={dados.habilitarAcessoRelatorioInadimplencia === 'S'}
                            id={`habilitar-acesso-relatorio-inadimplencia`}
                            onChange={e => {
                                dados.habilitarAcessoRelatorioInadimplencia = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        {/* MENU TOPO */}
        <div className={[styles.categoria, 'mb-2'].join(' ')}>
            <FontAwesomeIcon className={styles.icone} icon={["fas", "bars"]} />
            <span>Menu topo</span>
        </div>
        <Row className='mb-4'>
            <Col>
                <Card className='border-0 shadow-sm ' >
                    <Card.Header className={styles.cabecalho}>
                        <Card.Title as='h6' className={[styles.titulo].join(' ')} >
                            Opções
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Check
                            label='Habilitar acesso em Mapa dos Clientes'
                            checked={dados.habilitarAcessoMapaClientes === 'S'}
                            id={`habilitar-acesso-mapa-clientes`}
                            onChange={e => {
                                dados.habilitarAcessoMapaClientes = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Habilitar acesso em Relatórios'
                            checked={dados.habilitarAcessoRelatorios === 'S'}
                            id={`habilitar-acesso-relatorios`}
                            onChange={e => {
                                dados.habilitarAcessoRelatorios = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Habilitar acesso em Ponto'
                            checked={dados.habilitarPontoConsultaUsuario === 'S'}
                            id={`habilitar-ponto-consulta-usuario`}
                            onChange={e => {
                                dados.habilitarPontoConsultaUsuario = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            label='Habilitar acesso em Histórico'
                            checked={dados.habilitarAcessoHistorico === 'S'}
                            id={`habilitar-acesso-historico`}
                            onChange={e => {
                                dados.habilitarAcessoHistorico = e.target.checked ? 'S' : 'N';
                                alterarDados({...dados});
                            }}
                            className={styles.inputCheck}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        <Form.Group className="d-flex justify-content-around my-4">
            <Button variant="danger" onClick={() => {}}>Cancelar</Button>
            <Button variant="success" disabled={salvando} onClick={salvar}>
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Salvando</span>
                    </> : 
                    <span>Salvar</span>
                }
            </Button>
        </Form.Group>

    </>
}
