import React, {
    useEffect, 
    useState
} from 'react';
import { 
    Route, 
    Switch, 
    useHistory,
    useLocation,
    useRouteMatch 
} from 'react-router-dom';
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';

import Cadastro from './Cadastro';
import Consultar from './Consultar';
import Concluido from './Concluido';
import Finalizado from './Finalizado';
import EventosCartaOferta from './EventosCartaOferta';
import CadastroClienteEvento from './CadastroClienteEvento';
import CadastroClienteSeguroAventuraPropriedade from './CadastroClienteSeguroAventuraPropriedade';
import CadastroClienteSeguroAventuraPropriedadeDiarias from './CadastroClienteSeguroAventuraPropriedadeDiarias';

export default function SeguroAventura(){

    const { path } = useRouteMatch();
    const { search } = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const cartaOferta = useSelector(state => state.cartaOferta);

    const [carregando, alterarCarregando] = useState(false);
    
    // faz a busca da carta oferta
    async function buscarCartaOferta(cartaOfertaBusca, token=null) {

        // se já estiver realizando a requisição, para
        if(carregando) return;

        // bota pra carregar
        alterarCarregando(true);

        try {
            
            // faz a requisição
            const { data } = await axios
                .post(`/seguroAventura/cartaOferta`, 
                    { 
                        cartaOferta: cartaOfertaBusca,
                        tokenUsuario: token // com o token, o usuario poderá logar mesmo que o cliente esteja bloqueado
                    }
                )
            ;
            
            // limpa parametros na url
            history.replace({
                search: ''
            });

            // verifica se gerou token
            if (data.jwt) {

                // caso passou o token (está logando via painel)
                if(token) {

                    // atualiza status de login via url
                    history.replace({
                        search: '?loginViaPainel=S'
                    });
                }

                // adiciona autorização novamente
                axios.defaults.headers.common['Authorization'] = data.jwt;

                // salva no navegador para não precisar consultar novamente
                dispatch({
                    type: 'cartaOferta/ATUALIZAR',
                    dados: data
                });

            }

        } catch ({ response }) {

            // limpa parametros na url
            history.replace({
                search: ''
            });

            // logout
            dispatch({
                type: 'cartaOferta/ATUALIZAR',
                dados: null
            });

            // caso retornou erro
            if(response?.data && Object.keys(response.data)) {

                // resgata primeira chave do erro
                const erroChave = Object.keys(response.data)[0];
                
                // mensagem de erro
                toast(<>
                    <div
                        style={{ background: '#ff6271' }}
                    >
                        <div className="toast-header">
                            <strong className="mr-auto">Código não encontrado!</strong>
                            <button
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                            </button>
                        </div>
                        <div className="toast-body text-light">
                            <p>{response.data[erroChave]}</p>                            
                        </div>
                    </div>
                </>);

            } else {
            
                // mostra mensagem de erro
                toast(<>
                    <div
                        style={{ background: '#ff6271' }}
                    >
                        <div className="toast-header">
                            <strong className="mr-auto">Código não encontrado!</strong>
                            <button
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                            </button>
                        </div>
                        <div className="toast-body text-light">
                            <p>Por favor, tente novamente</p>
                        </div>
                    </div>
                </>);

            }

        } finally {
            alterarCarregando(false);
        }

    }

    // cria função para atrasar a execução do resgate dos dados em cache
    function promiseVaziaComAtraso() {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve(); // resolve a Promise após 300ms
          }, 300);
        });
    }
    
    useEffect(() => {
        
        // ativa carregamento
        alterarCarregando(true);                            
        
        // realiza promise para dar tempo de resgatar os dados do cache
        promiseVaziaComAtraso().then(() => {

            // se veio por meio da url
            if(search && search.length > 0) {

                // resgata parametros passados na url
                const parametrosUrl = new URLSearchParams(document.location.search);

                // resgata parametros
                const cartaOferta = parametrosUrl.get("cartaOferta"); 

                // resgata dados de login do usuario
                let dadosUsuarioRoca = window?.localStorage?.loginRoca ?? null;

                // prepara token
                let tokenUsuario = null;

                // caso encontrou login do roca
                if(dadosUsuarioRoca) {         
                    
                    // converte em objeto
                    dadosUsuarioRoca = JSON.parse(dadosUsuarioRoca);

                    // resgata token
                    tokenUsuario = dadosUsuarioRoca?.dadosUsuario?.token ?? null;
                }

                // caso tenha todas os parametros para logar com a carta oferta
                if(cartaOferta && tokenUsuario) {

                    // loga passando o token do usuario
                    return buscarCartaOferta(cartaOferta, tokenUsuario);  
                } 

            }

            // caso saiu da página e ficou sem o authorization
            if(!axios.defaults.headers.common?.Authorization) {
                
                // se possui a autorização para as requisições
                if(cartaOferta.dados && cartaOferta.dados?.cartaOferta) {

                    // consulta carta oferta no banco
                    buscarCartaOferta(cartaOferta.dados.cartaOferta);

                } else if(!cartaOferta?.dados) {
                    
                    // verifica se de fato está no cache novamente
                    if(
                        window?.localStorage &&
                        window?.localStorage?.cartaOferta && 
                        JSON.parse(window?.localStorage?.cartaOferta).dados
                    ) {

                        // consulta carta oferta no banco
                        buscarCartaOferta(JSON.parse(window?.localStorage?.cartaOferta).dados.cartaOferta);

                    } else {
                                    
                        // mensagem de não autorização
                        // toast(<>
                        //     <div
                        //         style={{ background: '#ff6271' }}
                        //     >
                        //         <div className="toast-header">
                        //             <strong className="mr-auto">Sem autorização!</strong>
                        //             <button
                        //                 className="ml-2 mb-1 close btn-outline-light outline-0"
                        //             >
                        //             </button>
                        //         </div>
                        //         <div className="toast-body text-light">
                        //             Por favor, realize o login novamente
                        //         </div>
                        //     </div>
                        // </>, { autoClose: 5000 });

                        alterarCarregando(false);

                        // limpa parametros na url
                        history.replace({
                            search: ''
                        });

                        // logout
                        dispatch({
                            type: 'cartaOferta/ATUALIZAR',
                            dados: null
                        });

                    }

                } else {

                    alterarCarregando(false);

                }

            } else {

                alterarCarregando(false);

            }

        });        

    }, [])
    
    return (carregando) ? 
        <></> : <>
            <Switch>
                <Route exact
                    path={`${path}/`}
                >
                    {!cartaOferta.dados ? <Consultar /> : <EventosCartaOferta />}
                </Route>
                <Route
                    exact
                    path={[`${path}/evento`, `${path}/evento/:token`]}
                >
                    {!cartaOferta.dados ? <Consultar /> : <Cadastro />}
                </Route>
                <Route
                    exact
                    path={[`${path}/concluido/:token`]}
                >
                    <Concluido />
                </Route>
                <Route
                    exact
                    path={[`${path}/finalizado/:token`]}
                >
                    <Finalizado />
                </Route>
                <Route
                    exact
                    path={[`${path}/cartaOferta`]}
                >
                    {!cartaOferta.dados ? <Consultar /> : <EventosCartaOferta />}
                </Route>
                <Route
                    exact
                    path={[`${path}/:token/cliente`]}
                >
                    <CadastroClienteEvento />
                </Route>
                <Route
                    exact
                    path={[`${path}/:token/propriedade`]}
                >
                    <CadastroClienteSeguroAventuraPropriedade />
                </Route>
                <Route
                    exact
                    path={[`${path}/:token/propriedade/diarias/:tokenDiarias`]}
                >
                    <CadastroClienteSeguroAventuraPropriedadeDiarias />
                </Route>
            </Switch>
        </>
    ;
}