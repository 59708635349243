import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Button, Form } from 'react-bootstrap';
import Evento from './Evento';
import EventosModalCadastrar from './../../Eventos/ModalCadastrar';
import ListaCheckbox from './../../Componentes/ListaCheckbox';

// componente
export default function Eventos({
    alvo=null, /*"1: Ficha Direito | 2: Ficha Seguro | 3: Ficha Consultoria | 4: Ficha Avaliação | 5: Ficha Imóveis | 6: Usuario | 7: Cliente"*/
    id=null,
    preCliente=null,
    proposta=null,
    idProposta=null,
    emissao=null,
    idEmissao=null,
    idResponsavel=null,
    alterarSalvandoEventos={alterarSalvandoEventos},
    cotacao=null,
    quantidadeAnexosCotacao=0,
    quantidadeAnexosProposta=0,
    quantidadeAnexosEmissao=0,
    atualizar=null
}){

    // adiciona evento
    let [carregando, alterarCarregando] = useState(false);
    let [salvando, alterarSalvando] = useState(false);
    let [carregandoAutomaticos, alterarCarregandoAutomaticos] = useState(false);
    let [eventosAutomaticos, alterarEventosAutomaticos] = useState([]);
    let [eventos, alterarEventos] = useState([]);
    let foiSalvo = useRef(false);
    let [parametros, alterarParametros] = useState({
        id_alvo: id,
        alvo_tabela: verificarAlvoTabela(),
        dados_extras: 'S',
        concluido: ['N']
    });

    // modal evento
    let [cadastrarEvento, alterarCadastrarEvento] = useState(null);

    

    // instancia componente que faz cadastro de evento
    // se for enviado o id do parente do evento, então já salva, 
    // se for nulo, retorna dados do evento
    // se for alteração, segue o padrão

    // // executa função salvarTodos eventos assim que o id for informado
    // useEffect(() => {
    //     // só executa uma vez
    //     if(!foiSalvo.current && ((id !== null || idProposta !== null || idEmissao !== null) && (eventos.length > 0 || eventosAutomaticos.length > 0))){
    //         foiSalvo.current = true;
    //         salvarTodos();
            
    //     }
    // }, [id, idProposta, idEmissao]);

    // useEffect(() => {
    //     if(idProposta !== null && (eventos.length > 0) || eventosAutomaticos.length > 0){
    //         salvarTodos();
    //     }
    // }, []);

    useEffect(() => {

        if(id !== null && eventosAutomaticos.length > 0){
            salvarTodos();
        }

    }, [eventosAutomaticos]);

    // carrega os eventos automáticos
    useEffect(() => {

        // if(parseInt(alvo) === 2){
        //     carregarEventosAutomaticosAlvo();
        // }else if(id === null){
        //     carregarEventosAutomaticosAlvo();
        // }

    }, [alvo, preCliente, proposta, emissao, cotacao, quantidadeAnexosCotacao, quantidadeAnexosProposta, quantidadeAnexosEmissao]);

    // carrega os eventos
    useEffect(() => {

        // carrega os eventos padrão
        if(id !== null){

            // consulta os eventos
            carregarEventos();
            
        }
    }, [parametros, atualizar, quantidadeAnexosCotacao, quantidadeAnexosProposta, quantidadeAnexosEmissao]);

    // carregar os eventos da ficha
    async function carregarEventos(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/eventosLegado`, {
                // params: {
                //     id_alvo: id,
                //     alvo_tabela: verificarAlvoTabela(),
                //     dados_extras: 'S',
                //     concluido: ['N']
                // }
                params: parametros
            });

            // finalizado
            retornoDados(data);

        }catch(e){

        }finally{

        }
    }

    // salvar todos
    async function salvarTodos(){
        alterarSalvando(true);
        alterarSalvandoEventos(true);

        // salva todos os eventos
        try{

            // faz as requisições
            let { data } = await axios.post(`/eventos/salvarTodos`, {
                dados: {
                    id_alvo: id,
                    alvo_tabela: verificarAlvoTabela(),
                    id_responsavel: idResponsavel
                },
                eventos,
                eventosAutomaticos
            });
            retornoDados(data);
            alterarSalvando(false);
            alterarSalvandoEventos(false);

        }catch({response}){

        }finally{

        }

    }

    // tratar retorno
    function retornoDados(retorno){
        alterarEventosAutomaticos([]);
        alterarEventos(retorno.registros);
    }

    // carrega os eventos automáticos do alvo
    async function carregarEventosAutomaticosAlvo(){
        alterarCarregandoAutomaticos(true);
        
        try{

            // faz a requisição
            let { data } = await axios.get('/eventosPadrao/alvo', {
                params: {
                    alvo: alvo,
                    pre_cliente: preCliente,
                    proposta: proposta,
                    emissao: emissao,
                    cotacao: cotacao,
                    alvo_tabela: verificarAlvoTabela(),
                    id_alvo: id
                }
            });
            let registrosNovos = data.registros;
            
            // finalizado
            if(parseInt(alvo) === 2){
                
                // se for destinado a cotação
                if(emissao === 'S'){
                    if(quantidadeAnexosEmissao > 0){
                        alterarEventosAutomaticos(registrosNovos);
                    }
                }else if(proposta === 'S'){
                    if(quantidadeAnexosProposta > 0){
                        alterarEventosAutomaticos(registrosNovos);
                    }
                }else if(cotacao === 'S'){
                    if(quantidadeAnexosCotacao > 0){
                        alterarEventosAutomaticos(registrosNovos);
                    }
                }else if(cotacao === 'N' && proposta === 'N' && emissao === 'N'){
                    alterarEventosAutomaticos(registrosNovos);
                }

            }else{
                alterarEventosAutomaticos(registrosNovos);
            }
            alterarCarregandoAutomaticos(false);

        }catch(e){

        }finally{

        }

    }

    // verifica alvo tabela
    function verificarAlvoTabela(){

        // verifica o alvo tabela
        let alvo_tabela = '';
        switch(parseInt(alvo)){
            case 6:
                alvo_tabela = 'usuarios';
            break;
            case 7:
                alvo_tabela = 'clientes';
            break;
            default:
                alvo_tabela = 'clientes_fichas';
        }
        return alvo_tabela;
    }

    return <>
        { cadastrarEvento &&
            <EventosModalCadastrar 
                // key={cadastrarEvento.id}
                fechou={() => {
                    alterarCadastrarEvento(null);
                }}
                salvou={dadosEvento => {
                    
                    // alterarEventos([...eventos, dadosEvento]);
                    let eventoAlterado = eventos.find(evento => {
                        if(evento === dadosEvento){
                            return evento;
                        }
                        return false;
                    });

                    // verifica se é um evento novo, ou recem cadastrado
                    if(eventoAlterado){
                        Object.assign(eventoAlterado, dadosEvento);
                        alterarEventos([...eventos]);
                    }else{
                        alterarEventos([...eventos, dadosEvento]);
                    }

                }}
                registro={cadastrarEvento}
                idAlvo={id}
                alvoTabela={verificarAlvoTabela()}
                preCliente={preCliente}
                idResponsavel={idResponsavel}
            />
        }
        <div
            className='d-flex align-items-start justify-content-between'
        >
            <Button
                className='mb-2 mr-3'
                onClick={() => {
                    alterarCadastrarEvento({
                        id: null
                    });
                }}
                size='sm'
            >
                <span>Cadastrar outro evento</span>
            </Button>

            { id !== null &&
                <Form.Group>
                    <Form.Label className='m-0'>Concluídos</Form.Label>
                    <div className="campo-flags">
                        <ListaCheckbox 
                            itens={[
                                {id: 'S', label: 'Sim'},
                                {id: 'N', label: 'Não'}
                            ]}
                            value={parametros.concluido}
                            label='consulta-evento-concluido'
                            onChange={(ids) => {
                                parametros.concluido = ids;
                                alterarParametros({...parametros});
                            }}
                        />
                    </div>
                </Form.Group>
            }
        </div>
        
        { eventosAutomaticos.length > 0 &&
            <div className='bg-white p-2 rounded shadow-sm pb-2 mb-4'>
                <p className='rounded'>
                    <i>Eventos padrão!</i>
                    {carregandoAutomaticos && 
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'pencil-alt']} />
                    }
                </p>
                <div className={styles.eventos}>
                
                    { eventosAutomaticos.map((evento, a) => 
                        <Evento 
                            key={evento.id}
                            modo={'automatico'}
                            dados={evento}
                            excluir={() => {
                                eventosAutomaticos.splice(a, 1);
                                alterarEventosAutomaticos([...eventosAutomaticos]);
                            }}
                        />
                    )}
                            
                </div>
            </div>
        }

        <div className={styles.eventos}>
            
            {eventos.map((evento, a) => 
                <Evento 
                    key={(evento.id === null) ? `n${a}` : evento.id}
                    dados={evento}
                    excluir={() => {
                        eventos.splice(a, 1);
                        alterarEventos([...eventos]);
                    }}
                    alterar={() => {
                        alterarCadastrarEvento(evento);
                    }}
                />
            )}
                    
        </div>
    </>
}
