import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import useIsMounted from 'ismounted';

// importa componentes
import { Button } from 'react-bootstrap';
import Arquivo, { ArquivoUpload } from './Arquivo';

// componente
export default function Anexos({
    tabela,
    id=null,
    salvando,
    size='md',
    habilitarCadastro=true,
    habilitarExcluir=true,
    externo=false,
    arquivosFila=null,
    arquivosTotal=null
}){
    
    // cria referencia
    let inputRef = React.createRef();

    // carrega os arquivos do cliente
    const isMounted = useIsMounted();
    const [carregando, alterarCarregando] = useState(true);
    const [arquivos, alterarArquivos] = useState([]);
    const [arquivosUpload, alterarArquivosUpload] = useState([]);

    // carregar os arquivos
    useEffect(() => {
        
        // faz a consulta dos anexos
        if(id !== null){
            axios.get('/anexos', {
                params: {
                    tabela: tabela,
                    id_tabela: id
                }
            })
            .then(({data, ...retorno}) => {
                alterarArquivos(data.registros);
                alterarCarregando(false);
            })
            .catch(() => {})
            .then(() => {});
        }else{
            alterarCarregando(false);
        }

    }, []);      

    // adicionar a fila de arquivos para fazer upload
    function adicionarFila(){

        // faz a requisição para cada arquivo escolhido
        let arrayNovo = arquivosUpload;
        Array.from(inputRef.current.files).forEach(arquivo => {
            // console.log(arquivo);
            arrayNovo.push({arquivo, progresso: null, erro: null});
        });
        alterarArquivosUpload([...arrayNovo]);

        // salva
        salvar();
    }

    // salva arquivo
    function salvar(){

        // se não possuir id ainda, ignora
        if(id === null){
            return;
        }

        // faz as requisições para salvar os arquivos
        arquivosUpload.forEach((dados, a) => {

            // faz a requisição
            let salvarImagem = new FormData();
            salvarImagem.append('arquivo', dados.arquivo);
            salvarImagem.append('tabela', tabela);
            salvarImagem.append('id', id);

            // verifica o progresso da imagem do card
            function progressoArquivo(progressEvent){
                let progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                dados.progresso = progress;
                alterarArquivosUpload([...arquivosUpload]);
            }

            // salva as imagens
            axios.post(`/anexos`, salvarImagem, {
                onUploadProgress: progressoArquivo,
                headers: {'Content-type': 'multipart/form-data'},
                params: {
                    externo: externo ? 'S' : 'N'
                }
            })
            .then(({data, ...retorno}) => {

                // se o componente se fechou, faz nada
                if (!isMounted.current) {
                    return;
                }

                // atualiza
                let index = arquivosUpload.indexOf(dados);
                if(index >= 0){
                    arquivosUpload.splice(index, 1);
                }
                alterarArquivosUpload([...arquivosUpload]);
                
                // bem sucedido
                arquivos.push(data.dados);
                alterarArquivos([...arquivos]);
            })
            .catch(({response, ...retorno}) => {
                if(response){

                    // resposta
                    if(response.status === 400){
                        // alterarErros(response.data);
                        dados.erro = response.data.arquivo;
                        alterarArquivosUpload([...arquivosUpload]);
                    }
                }
            })
            .then(() => {

            });

            

        });
    }

    useEffect(() => {
        let quantidadeArquivosUpload = arquivosUpload.filter(arquivo => arquivo.erro === null ? true : false).length;
        if(quantidadeArquivosUpload === 0){
            salvando(false);
        }else if(id !== null){
            salvando(true);
        }
    }, [arquivosUpload])
    
    // após o id ser informado, todos os arquivos serão salvos
    useEffect(() => {
        if(id !== null && arquivosUpload.filter(arquivo => arquivo.erro === null ? true : false).length > 0){
            salvar();
        }
    }, [id]);

    // atualiza contagem de arquivos a atualizar
    useEffect(() => {
        if(arquivosFila && arquivosFila(arquivosUpload.length));
    }, [arquivosUpload]);

    // atualiza a quantidade total de arquivos que o cadastro possui
    useEffect(() => {
        if(arquivosTotal && arquivosTotal(arquivos.length));
    }, [arquivos, arquivosUpload]);  

    // se estiver carregando
    if(carregando || !isMounted.current){
        return <div>Carregando...</div>
    }

    return <>

        <div className={[styles.arquivos, 'mb-3'].join(' ')}>
            {arquivos.map((arquivo, a) => 
                <Arquivo 
                    habilitarExcluir={habilitarExcluir}
                    key={arquivo.id}
                    arquivo={arquivo}
                    // excluir={excluir}
                    excluido={() => {
                        arquivos.splice(a, 1);
                        alterarArquivos([...arquivos]);
                    }}
                    abrir={() => {
                        console.log(arquivo);
                        // window.open(`${process.env.REACT_APP_URL_PHP}/arquivos/anexos/${arquivo.nome_temporario}`, '_blank');
                    }}
                    size={size}
                />    
            )}

            { arquivosUpload.map((upload, a) => 
                <ArquivoUpload 
                    key={`${upload.arquivo.name}${a}`}
                    upload={upload}
                    emEspera={id === null}
                    size={size}
                />
            )}
        </div>
        <input hidden type='file' multiple ref={inputRef}
            onChange={adicionarFila}
        />

        { habilitarCadastro &&
            <Button 
                variant='padrao'
                size={size}
                onClick={() => inputRef.current.click()}
                disabled={arquivosUpload.filter(arquivo => arquivo.erro === null ? true : false).length > 0 && id !== null}
            >
                <span>Adicionar arquivo</span>
            </Button>
        }
    </>
}
