

export default function(state = Object.assign({
    logado: false,
    dadosUsuario: {},
    dadosCliente: {},
    permissao: {},
    horaLogin: null,
}, JSON.parse(window.localStorage.getItem('loginRoca'))), action){

    // // retorna estado
    // if(action.reducer !== 'login'){
    //     return state;
    // }

    // ações globais
    switch(action.type){
        case 'FAZER_LOGIN':
            let dadosAtualizar = {
                logado: true,
                dadosUsuario: action.dadosUsuario, // guardar os dados do usuário
                dadosCliente: action.dadosCliente,
                permissao: action.permissao,
                horaLogin: action.horaLogin
            };
            window.localStorage.setItem('loginRoca', JSON.stringify(dadosAtualizar));
            return Object.assign({}, state, dadosAtualizar);
        case 'ATUALIZAR_TEMPO_LOGADO': 
            const dadosAtual = JSON.parse(window.localStorage.getItem('loginRoca'));
            if(dadosAtual) dadosAtual.horaLogin = new Date();
            window.localStorage.setItem('loginRoca', JSON.stringify(dadosAtual));
            return Object.assign({}, state, dadosAtual);
        case 'FAZER_LOGGOF':
            window.localStorage.removeItem('loginRoca');
            return Object.assign({}, state, {
                logado: false,
                dadosUsuario: {},
                dadosCliente: {},
                permissao: {},
                horaLogin: null
            });
        case 'ATUALIZAR_PERMISSAO': 
            let atualizarPemissao = JSON.parse(window.localStorage.getItem('loginRoca'));
            atualizarPemissao.permissao = action.permissao;
            window.localStorage.setItem('loginRoca', JSON.stringify(atualizarPemissao));
            return Object.assign({}, state, atualizarPemissao);
        default: 
            return state
    }
}