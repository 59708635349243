import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Row, Col, Container, Form } from 'react-bootstrap';
import Anexos from './../../Componentes/Anexos';
import ComboResponsavel from './../../Componentes/ComboResponsavel';
import InputDataCombo from './../../Componentes/InputDataCombo';
import InputHora from './../../Componentes/InputHora';
import InputInteiro from './../../Componentes/InputInteiro';

// componente
function ModalCadastrar({
    registro=null,
    fechou,
    salvou,
    idAlvo=null,
    alvoTabela,
    preCliente='N',
    idResponsavel= null,
    login
}){

    // mostrar modal
    const [mostrar, alterarMostrar] = useState(true);
    const [carregando, alterarCarregando] = useState(true);
    const [dados, alterarDados] = useState({
        id: null,
        id_responsavel: login.dadosUsuario.id,
        id_evento_padrao: null,
        alvo_tabela: alvoTabela,
        id_alvo: idAlvo,
        descricao: null,
        data_programada: moment().format('YYYY-MM-DD'),
        data_reagendamento: null,
        concluido: 'N',
        observacao_concluido: '',
        data_concluido: null,
        hora_concluido: null,
        tipo_aniversario: 'N',
        tipo_vigencia: 'N',
        data_lembrar: null,
        quantidade_lembrar: '1',
        periodo_lembrar: 'D',
        recorrente: 'N',
        recorrente_periodo: 'D',
        recorrente_quantidade: '1',
        recorrente_pai: null,
        recorrente_data_limite: null,
        recorrente_repetir: null,
        pre_cliente: preCliente

    });
    const [salvando, alterarSalvando] = useState(false);
    const [modo, alterarModo] = useState('cadastro'); // cadastro, edicao, cadastroAutomatico
    const [salvandoAnexos, alterarSalvandoAnexos] = useState(false);
    const [filhos, alterarFilhos] = useState([]);
    const [clicouSalvar, alterarClicouSalvar] = useState(false);

    // se possuir filhos
    // bloquear campos:
    // - data_programada, recorrente_periodo, recorrente_quantidade    

    // consulta o id do evento
    async function consultarId(){
        
        try{

            // faz a requisição
            let { data } = await axios.get(`/eventos/${registro.id}`);

            // altera os dados
            Object.assign(dados, data.registro);
            alterarDados({...dados});
            alterarFilhos(data.filhos);
            alterarCarregando(false);

        }catch({response}){

            if(response){
                
                // erro
                if(response.status === 404){
                    window.alert('Registro não está mais cadastrado');
                    fechou();
                }
            }

        }finally{

        }

    }

    // salva
    async function salvar(){

        // if(idAlvo === null){
        if(modo === 'cadastroAutomatico'){

            // só retorna os dados
            Object.assign(registro, dados);
            salvou(registro);
            alterarMostrar(false);
        }else{
            alterarSalvando(true);
            alterarClicouSalvar(true);

            try{

                // faz a requisição
                let { data } = await axios.post(`/eventos`, {dados: dados});

                // salvo
                Object.assign(registro, data.registro);
                salvou(registro);

            }catch({response}){
                alterarClicouSalvar(false);
            }finally{
                alterarSalvando(false);
            }
        }

    }

    // carrega
    useEffect(() => {

        if(registro.id !== null){

            // edição
            alterarModo('edicao');

            // carrega dados do evento
            consultarId();



        }else{

            // verifica o modo
            if(idAlvo === null){
                alterarModo('cadastroAutomatico');
            }else{
                alterarModo('cadastro');
            }

            // preenche os dados que já foram cadastrados
            Object.assign(dados, registro);
            alterarDados({...dados});
            alterarCarregando(false);
        }

        

    }, []);

    // ao finalizar cadastro
    useEffect(() => {

        // finalizou cadastro
        if(clicouSalvar && !salvando && !salvandoAnexos){
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Evento salvo com sucesso!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>);
            alterarMostrar(false);
        }

    }, [salvando, salvandoAnexos]);
    
    // retorna modal de carregando
    if(carregando){
        return <Modal 
            show={mostrar}
            onHide={() => {}}
        >
            <Modal.Body>
                <p>Carregando Evento <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} /></p>
            </Modal.Body>
        </Modal>
    }

    return <Modal 
        show={mostrar} 
        onExited={fechou}
        onHide={() => {}}
        size={modo === 'cadastroAutomatico' ? 'md' : 'lg'}
    >
        <Modal.Header>
            <Modal.Title>
                Cadastro Evento
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Row>
                    <Col md={modo === 'cadastroAutomatico' ? '12' : '6'} >
                        <Form.Group>
                            <Form.Label>Responsável</Form.Label>
                            <ComboResponsavel 
                                id={dados.id_responsavel}
                                alterou={(id) => {
                                    dados.id_responsavel = id;
                                    alterarDados({...dados});
                                }}
                            />
                        </Form.Group>
                    
                        <Form.Row>
                            <Col md='6'>
                                <Form.Label>Data Programada</Form.Label>
                                <InputDataCombo 
                                    data={dados.data_programada}
                                    onChange={data => {
                                        dados.data_programada = data;
                                        alterarDados({...dados});
                                    }}
                                    disabled={filhos.length > 0}
                                />
                            </Col>
                            <Col md='6'>
                                <Form.Label>Lembrar Antes</Form.Label>
                                <Form.Row>
                                    <Col md='4'>
                                        <InputInteiro 
                                            className='text-center'
                                            valor={dados.quantidade_lembrar || '1'}
                                            onChange={valorNovo => {
                                                dados.quantidade_lembrar = valorNovo;
                                                alterarDados({...dados});
                                            }}
                                        />
                                    </Col>
                                    <Col md='8'>
                                        <Form.Control
                                            as='select'
                                            value={dados.periodo_lembrar}
                                            onChange={e => {
                                                dados.periodo_lembrar = e.target.value;
                                                alterarDados({...dados});
                                            }}
                                        >
                                            {[
                                                { value:'D', children:'Dia(s)' },
                                                { value:'S', children:'Semana(s)' },
                                                { value:'M', children:'Mes(es)' },
                                                { value:'A', children:'Ano(s)' }
                                            ].map(dias => 
                                                <option key={dias.value} {...dias} />
                                            )}
                                        </Form.Control>
                                    </Col>
                                </Form.Row>
                            </Col>
                        </Form.Row>

                        {!dados?.recorrente_pai && <div className='p-2 bg-light rounded mt-3'>
                            <Form.Check 
                                inline
                                label='Recorrente'
                                type={'checkbox'}
                                id={`checkbox-recorrente`}
                                checked={dados.recorrente === 'S'}
                                onChange={e => {
                                    dados.recorrente = e.target.checked ? 'S' : 'N';
                                    alterarDados({...dados});
                                }}
                            />
                            {dados.recorrente === 'S' &&
                                <div className='mt-1'>
                                    <Form.Row>
                                        <Col md={{span: 4}}>
                                            <Form.Label>Repetições</Form.Label>
                                            <InputInteiro 
                                                placeholder='Sem limites'
                                                className='text-center form-control-sm'
                                                valor={dados.recorrente_repetir || ''}
                                                aceitarNull={true}
                                                onChange={valorNovo => {
                                                    console.log(valorNovo);
                                                    if(valorNovo === ''){
                                                        valorNovo = null;
                                                    }
                                                    dados.recorrente_repetir = valorNovo;
                                                    alterarDados({...dados});
                                                }}
                                            />
                                        </Col>
                                        <Col md={{span:6, offset: 2}}>
                                            <Form.Label>Repetir a cada</Form.Label>
                                            <Form.Row>
                                                <Col md='5'>
                                                    <InputInteiro 
                                                        className='text-center form-control-sm'
                                                        valor={dados.recorrente_quantidade || '1'}
                                                        onChange={valorNovo => {
                                                            dados.recorrente_quantidade = valorNovo;
                                                            alterarDados({...dados});
                                                        }}
                                                        disabled={filhos.length > 0}
                                                    />
                                                </Col>
                                                <Col md='7'>
                                                    <Form.Control 
                                                        size='sm'
                                                        as='select'
                                                        value={dados.recorrente_periodo}
                                                        onChange={e => {
                                                            dados.recorrente_periodo = e.target.value;
                                                            alterarDados({...dados});
                                                        }}
                                                        disabled={filhos.length > 0}
                                                    >
                                                    {[
                                                        { value:'D', children:'Dia(s)' },
                                                        { value:'S', children:'Semana(s)' },
                                                        { value:'M', children:'Mes(es)' },
                                                        { value:'A', children:'Ano(s)' }
                                                    ].map(dias => 
                                                        <option key={dias.value} {...dias} />
                                                    )}
                                                    </Form.Control>
                                                </Col>
                                            </Form.Row>
                                        </Col>
                                    </Form.Row>
                                </div>
                            }
                        </div>}

                        <Form.Group className='mt-3'>
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control 
                                as='textarea' 
                                value={dados.descricao || ''}
                                onChange={e => {
                                    dados.descricao = e.target.value;
                                    alterarDados({...dados});
                                }}
                                rows='4'
                                maxLength={1000}
                            />
                        </Form.Group>

                    </Col>
                    {modo !== 'cadastroAutomatico' &&
                        <Col md='6'>
                            <div>
                                <Form.Label>Data de Conclusão</Form.Label>
                                <Form.Row>
                                    <Col md='4' className='pt-1'>
                                        <Form.Check 
                                            inline
                                            label='Concluído'
                                            type={'checkbox'}
                                            id={`checkbox-evento-concluido`}
                                            checked={dados.concluido === 'S'}
                                            onChange={e => {
                                                dados.concluido = e.target.checked ? 'S' : 'N';
                                                if(e.target.checked){
                                                    dados.data_concluido = moment().format('YYYY-MM-DD');
                                                    dados.hora_concluido = moment().format('HH:mm:ss');
                                                }else{
                                                    dados.data_concluido = null;
                                                    dados.hora_concluido = null;
                                                }
                                                alterarDados({...dados});
                                            }}
                                        />
                                    </Col>
                                    <Col md='8'>
                                        <Form.Row>
                                            <Col md='7'>
                                                <InputDataCombo 
                                                    data={dados.data_concluido}
                                                    onChange={data => {
                                                        dados.data_concluido = data;
                                                        alterarDados({...dados});
                                                    }}
                                                    disabled={dados.concluido === 'N'}
                                                />
                                            </Col>
                                            <Col md='5'>
                                                <InputHora 
                                                    className='text-center'
                                                    value={dados.hora_concluido}
                                                    onChange={novaHora => {
                                                        dados.hora_concluido = novaHora;
                                                        alterarDados({...dados});
                                                    }}
                                                    disabled={dados.concluido === 'N'}
                                                />
                                            </Col>
                                        </Form.Row>
                                    </Col>
                                </Form.Row>
                            </div>
                            <Form.Group className='mt-3'>
                                <Form.Label>Observação ao concluir</Form.Label>
                                <Form.Control 
                                    as='textarea'
                                    rows='3'
                                    value={dados.observacao_concluido || ''}
                                    onChange={e => {
                                        dados.observacao_concluido = e.target.value;
                                        alterarDados({...dados});
                                    }}
                                />
                            </Form.Group>
                            <Anexos 
                                tabela={'eventos'}
                                id={dados.id}
                                salvando={(flag) => {
                                    alterarSalvandoAnexos(flag);
                                }}
                                size='sm'
                            />
                        </Col>
                    }
                </Row>
            </Container>
            {salvandoAnexos &&
                <p>Aguarde, salvando os anexos!</p>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant='danger'
                onClick={() => {alterarMostrar(false)}}
                disabled={salvando || salvandoAnexos}
            >
                <span>Fechar</span>
            </Button>
            <Button
                onClick={salvar}
                variant='success'
                disabled={dados.data_programada === null || salvandoAnexos || salvando}
            >
                {salvando ? 
                    <>
                        <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                        <span>Salvando</span>
                    </> : 
                    <span>
                        {modo === 'cadastroAutomatico' ? 'Incluir' : 'Salvar'}
                    </span>
                }
            </Button>
        </Modal.Footer>
    </Modal>

}

// importa states
let mapStateToProps = function(state){
    let estado = {
        login: state.login
    };
    return estado;
};

// retorna página de consulta
export default connect(mapStateToProps)(ModalCadastrar);


