import React, { 
    useState, 
    useEffect
} from 'react';
import { 
    Prompt, 
    useParams, 
    useHistory 
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';

import { 
    Row, 
    Col, 
    Card, 
    Form, 
    Alert, 
    Button
} from 'react-bootstrap';
import { 
    faSpinner, 
    faExclamationCircle 
} from '@fortawesome/free-solid-svg-icons';
import { FichaCadastrarContexto } from './FichaCadastrarContexto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FichaComponent } from './components';
import ComboResponsavel from './../../Componentes/ComboResponsavel';
import Atalhos from './Atalhos';
import DadosClienteFormulario from './DadosClienteFormulario';
import Formulario from './Formulario';
import EventosFicha from './EventosFicha';
import Emissao from './Emissao';
import Proposta from './Proposta';

/**
 * 
 * Etapa:
 * 0: não é apresentada no funil
 * 1: cotação
 * 2: renovação
 * 3: proposta
 * 4: emissão
 * 
 * Obs.: Eventos serão salvos junto com a ficha
 * - Eventos que serão excluidos, seram apresentados
 * - Eventos novos, seram apresentados
 * - Se dara ao fato de alterações no curso da ficha
 * - se alterado a etapa, será recarregado os eventos que serão alterados
 * 
 * Obs.: se for uma ficha de renovação, carregar todos os dados da ficha de origem e incluir, os arquivos, fazer a cópia
 * 
 * Obs.: todos os arquivos aqui serão salvos no momento em que clicar para salvar, até mesmo em edição
 */



// cadastro de ficha de atendimento
export default function Cadastrar({

}){

    // estados
    const { login, painel } = useSelector(state => state);
    const { id, id_cliente, id_ficha_renovar } = useParams();
    const history = useHistory();
    
    const [carregando, alterarCarregando] = useState(true);
    const [carregandoEventosAutomatico, alterarCarregandoEventosAutomatico] = useState(false);
    const [salvando, alterarSalvando] = useState(false);
    const [salvandoAnexos, alterarSalvandoAnexos] = useState(false);
    const [dadosCliente, alterarDadosCliente] = useState(null);
    const [erros, alterarErros] = useState({});
    const [autoMarcarCotacao, alterarAutoMarcarCotacao] = useState(true);
    const [dados, alterarDados] = useState({
        id: id || null,
        id_responsavel: login.dadosUsuario.id,
        id_cliente: id_cliente,
        tipo: id_ficha_renovar ? '2' : '2',
        titulo: '',
        descricao: '',
        observacao: '',
        possui_vigencia: 'N',
        data_vigencia: null,
        id_evento_vigencia: null,
        etapa: '0',
        cotacao: 'N',
        id_ficha_renovado: null,
        placa_veiculo: null,
        data_edicao: null
    });
    const [idFichaRenovacao, alterarIdFichaRenovacao] = useState(id_ficha_renovar || null);
    const [dadosVigencia, alterarDadosVigencia] = useState({
        quantidade_lembrar: parseInt(painel.configuracoesSistema.fichaHabilitarVigenciaDiasLembrar),
        periodo_lembrar: 'D'
    });
    const [anexosFormulario, alterarAnexosFormulario] = useState([]); // {remover: false} {upload: false} {dados: {}} -- um desses 3 terá
    // eventos relacionados a ficha
    const [eventos, alterarEventos] = useState([]);                  // os eventos que o cliente já possui
    const [eventosNovos, alterarEventosNovos] = useState([]);        // os eventos que seram adicionados quando finalizado o cadastro
    const [eventosRemover, alterarEventosRemover] = useState([]);    // os eventos que seram removidos

    // dados da proposta
    const [dadosProposta, alterarDadosProposta] = useState(null);
    const [dadosPropostaParcelas, alterarDadosPropostaParcelas] = useState([]);
    const [anexosProposta, alterarAnexosProposta] = useState([]);

    // dados da emissão
    const [dadosEmissao, alterarDadosEmissao] = useState(null);
    const [dadosEmissaoParcelas, alterarDadosEmissaoParcelas] = useState([]);
    const [anexosEmissao, alterarAnexosEmissao] = useState([]);
    const [agendarSmsEmissao, alterarAgendarSmsEmissao] = useState({
        agendar: 'N',
        idSmsModelo: null
    });    
    const [assinatura, alterarAssinatura] = useState(JSON.stringify({}));

    // carrega os dados do cliente
    async function consultarIdCliente(){

        try {

            // faz a requisição
            const { data } = await axios.get(`/clientes/${id_cliente}`);
            alterarDadosCliente(data.dados);            

        } catch({ response }){
            console.error(response);
        } finally {
            alterarCarregando(false);
        }
    }

    // carrega os dados da ficha
    async function consultarId(){

        try {

            // faz a requisição
            const { data } = await axios.get(`/fichas/${dados.id}`);
            alterarDadosCliente(data.cliente);
            alterarDados(data.dados);
            alterarAnexosFormulario(data.anexosFormulario);
            alterarDadosProposta(data.proposta);
            alterarDadosPropostaParcelas(data.propostaParcelas);
            alterarAnexosProposta(data.anexosProposta);
            alterarDadosEmissao(data.emissao);
            alterarDadosEmissaoParcelas(data.emissaoParcelas);
            alterarAnexosEmissao(data.anexosEmissao);
            if(data.dados.possui_vigencia == 'S'){
                alterarDadosVigencia(data.vigencia);
            }

            // se é uma ficha de renovação
            if(data.fichaRenovada !== null){
                alterarIdFichaRenovacao(data.fichaRenovada.id);
            }

            alterarAssinatura(JSON.stringify({
                ...JSON.parse(assinatura),
                dadosCliente: data.cliente,
                dados: data.dados,
                anexosFormulario: data.anexosFormulario,
                dadosProposta: data.proposta,
                dadosPropostaParcelas: data.propostaParcelas,
                anexosProposta: data.anexosProposta,
                dadosEmissao: data.emissao,
                dadosEmissaoParcelas: data.emissaoParcelas,
                dadosVigencia: data?.vigencia ?? dadosVigencia,
                agendarSmsEmissao,
                idFichaRenovacao: data?.fichaRenovada?.id ?? idFichaRenovacao
            }));            

        } catch({ response }) {
            console.error(response);
        } finally {
            alterarCarregando(false);
        }

    }
    
    // salva registro
    async function salvar(){
        alterarSalvando(true);
        alterarErros({});

        try {

            // faz a requisição
            const { data } = await axios.post(`/fichas`, {
                dados,
                dadosProposta,
                dadosPropostaParcelas,
                dadosVigencia,
                dadosEmissao,
                dadosEmissaoParcelas,
                agendarSmsEmissao,
                eventosNovos,
                eventosRemover,
                idFichaRenovacao,
                eventos: eventos.map(evento => evento.dados),
                anexosEmissaoRemover: anexosEmissao.filter(anexo => anexo.remover).map(anexo => anexo.dados.id),
                anexosPropostaRemover: anexosProposta.filter(anexo => anexo.remover).map(anexo => anexo.dados.id),
                anexosFormularioRemover: anexosFormulario.filter(anexo => anexo.remover).map(anexo => anexo.dados.id),
            });

            // inicia a etapa de salvar os arquivos
            alterarSalvandoAnexos(true);

            // salva todos os anexos que pertecem a ficha
            if(anexosFormulario.filter(anexo => anexo.upload ? true : false).length > 0){
                await salvarAnexos('ficha', data.dados.id);
            }
            if(data.proposta !== null && anexosProposta.filter(anexo => anexo.upload ? true : false).length > 0){
                await salvarAnexos('proposta', data.proposta.id);
            }
            if(data.emissao !== null && anexosEmissao.filter(anexo => anexo.upload ? true : false).length > 0){
                await salvarAnexos('emissao', data.emissao.id);
            }

            // finaliza
            alterarSalvandoAnexos(false);

            // ficha salva com sucesso
            // mensagem em caso de erro com algum arquivo: 
            // A ficha foi salva, porém alguns anexos não puderam ser salvos, tente enviar novamente!
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Ficha de atendimento salva com sucesso!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>);

            // finalizado altera os dados de retorno
            alterarDados(data.dados);
            alterarDadosProposta(data.proposta);
            alterarDadosPropostaParcelas(data.propostaParcelas);
            alterarDadosEmissao(data.emissao);
            alterarDadosEmissaoParcelas(data.emissaoParcelas);
            if(data.dados.possui_vigencia == 'S'){
                alterarDadosVigencia(data.vigencia);
            }

            // remove os anexos que eram para serem excluídos
            alterarAnexosFormulario(anexosFormulario.filter(anexo => !anexo.remover));
            alterarAnexosProposta(anexosProposta.filter(anexo => !anexo.remover));
            alterarAnexosEmissao(anexosEmissao.filter(anexo => !anexo.remover));

            // reseta variaveis de eventos
            alterarEventosNovos([]);
            alterarEventosRemover([]);

            alterarAssinatura(JSON.stringify({
                ...JSON.parse(assinatura),
                dados: data.dados,
                dadosCliente: !data?.cliente ? JSON.parse(assinatura)?.dadosCliente : data.cliente,
                anexosFormulario: anexosFormulario.filter(anexo => !anexo.remover),
                dadosProposta: data.proposta,
                dadosPropostaParcelas: data.propostaParcelas,
                anexosProposta: anexosProposta.filter(anexo => !anexo.remover),
                dadosEmissao: data.emissao,
                dadosEmissaoParcelas: data.emissaoParcelas,
                dadosVigencia: data?.vigencia ?? dadosVigencia,
                agendarSmsEmissao,
                idFichaRenovacao: data?.fichaRenovada?.id ?? idFichaRenovacao,
                eventosNovos: [],
                eventosRemover: []
            }));   

            if(data?.cliente) {
                alterarDadosCliente(data.cliente);
            }

            // altera a url
            history.replace(`/painel/fichas/${dados.id_cliente}/alterar/${data.dados.id}`);

        }catch({response}){
            if(response){
                if(response.status === 400){
                    alterarErros(response.data);
                    toast(<>
                        <div
                            style={{background: '#ff6271'}}
                        >
                            <div className="toast-header">
                                <strong className="mr-auto">Houve alguns erros :(</strong>
                                <button 
                                    className="ml-2 mb-1 close btn-outline-light outline-0"
                                >
                                </button>
                            </div>
                            <div className="toast-body text-light">
                                Verifique os campos que precisam ser preenchidos corretamente!
                            </div>
                        </div>
                    </>);
                }
            }
        }finally{
            alterarSalvando(false);
        }

    }

    // salvar os anexos // em: ficha, proposta, emissao
    async function salvarAnexos(em = 'ficha', idAlvo){

        // array contendo todos os anexos a serem salvos
        let salvandoAnexos = [];

        // verifica quais anexos seram salvos
        let anexosSalvar = [];
        let tabela = '';
        switch(em){
            case 'ficha': 
                anexosSalvar = anexosFormulario.filter(anexo => anexo.upload ? true : false); 
                tabela = 'clientes_fichas';
            break;
            case 'proposta': 
                anexosSalvar = anexosProposta.filter(anexo => anexo.upload ? true : false); 
                tabela = 'cliente_ficha_proposta';
            break;
            case 'emissao': 
                anexosSalvar = anexosEmissao.filter(anexo => anexo.upload ? true : false); 
                tabela = 'cliente_ficha_emitido';
            break;
        }

        // faz a requisição para salvar os arquivos
        anexosSalvar.forEach(async arquivo =>{

            // dados para salvar a ibagem
            let formData = new FormData();
            formData.append('arquivo', arquivo.upload.arquivo);
            formData.append('tabela', tabela);
            formData.append('id', idAlvo);

            // verifica o progresso da imagem do card
            function progressoArquivo(progressEvent){
                arquivo.upload.progresso = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                switch(em){
                    case 'ficha': alterarAnexosFormulario([...anexosFormulario]); break;
                    case 'proposta': alterarAnexosProposta([...anexosProposta]); break;
                    case 'emissao': alterarAnexosEmissao([...anexosEmissao]); break;
                }
            }

            // ajusta dados
            let requisicao = axios.post(`/anexos`, formData, {
                onUploadProgress: progressoArquivo,
                headers: {'Content-type': 'multipart/form-data'},
            })
            .then(({data}) => {
                arquivo.dados = data.dados;
                arquivo.upload = null;
                switch(em){
                    case 'ficha': alterarAnexosFormulario([...anexosFormulario]); break;
                    case 'proposta': alterarAnexosProposta([...anexosProposta]); break;
                    case 'emissao': alterarAnexosEmissao([...anexosEmissao]); break;
                }
            })
            .catch(({response}) => {
                if(response){
                    arquivo.upload.erro = true;
                    switch(em){
                        case 'ficha': alterarAnexosFormulario([...anexosFormulario]); break;
                        case 'proposta': alterarAnexosProposta([...anexosProposta]); break;
                        case 'emissao': alterarAnexosEmissao([...anexosEmissao]); break;
                    }

                    toast(<>
                        <div
                            style={{background: '#ff6271'}}
                        >
                            <div className="toast-header">
                                <strong className="mr-auto">Não Conseguimos salvar seu arquivo :(</strong>
                                <button 
                                    className="ml-2 mb-1 close btn-outline-light outline-0"
                                >
                                </button>
                            </div>
                            <div className="toast-body text-light">
                                Não foi possível salvar o arquivo <b>{arquivo.upload.arquivo.name}</b>!
                            </div>
                        </div>
                    </>);
                }
            });

            // incrementa
            salvandoAnexos.push(requisicao);

        });

        // aguarda finalizar todos
        await Promise.all(salvandoAnexos);

        // finalizado
        return true;


    }

    // consulta eventos relacionado a etapa
    async function consultarEventosEtapa(){
        alterarCarregandoEventosAutomatico(true);

        try {

            let preCliente = dados.etapa === '4' ? 'N' : dadosCliente.pre_cliente;

            // faz a requisição
            const { data } = await axios.get(`/fichas/eventosEtapa`, { params: {
                etapa: dados.etapa,
                alvo: dados.tipo,
                id_alvo: dados.id,
                alvo_tabela: 'clientes_fichas',
                pre_cliente: preCliente
            }});
            alterarEventosNovos(data.registros);
            alterarEventosRemover(data.remover);
            alterarAssinatura(JSON.stringify({
                ...JSON.parse(assinatura),
                eventosNovos: data.registros,
                eventosRemover: data.remover
            }));

        }catch({ response }){
            console.error(response);
        } finally {
            alterarCarregandoEventosAutomatico(false);
        }
    }

    // renovar essa emissão
    async function renovar(){
        window.location.href = `/painel/fichas/${dados.id_cliente}/renovar/${dados.id}`;
    }

    // inicializa
    useEffect(() => {

        // se for edição carrega os dados
        if(dados.id !== null){
            consultarId();
        }else{
            consultarIdCliente();
        }
    }, []);

    // altera a etapa
    useEffect(() => {

        // se estiver salvando não faz os corre
        if(!carregando && !salvando){

            // define a etapa geral, já considera arquivo nesse formulario
            let novaEtapa = '0';

            // se for do tipo seguro
            if(dados.tipo === '2'){

                // etapa de cotação
                if(dados.cotacao === 'S' && anexosFormulario.filter(anexo => !anexo.remover).length > 0){
                    novaEtapa = '1';
                }

                // se for renovação
                if(novaEtapa === '1' && idFichaRenovacao !== null){
                    novaEtapa = '2';
                }

                // se possuir proposta
                if(dadosProposta !== null && anexosProposta.filter(anexo => !anexo.remover).length > 0){
                    novaEtapa = '3';
                }

                // se possuir emissão
                if(dadosEmissao !== null && anexosEmissao.filter(anexo => !anexo.remover).length > 0){
                    novaEtapa = '4';
                }
            }

            // altera a variavel
            dados.etapa = novaEtapa;
            alterarDados({...dados});
        }

    }, [
        dados?.tipo,
        dados?.cotacao,
        anexosFormulario,
        dadosProposta,
        anexosProposta,
        dadosEmissao,
        anexosEmissao
    ]);

    // carrega os eventos que seram cadastrados ao ser alterado a etapa
    useEffect(() => {
        // carregar eventos referente a etapa
        if(!carregando && !salvando){
            consultarEventosEtapa();
        }
    }, [dados.etapa, carregando, salvando]);

    // carrega os eventos iniciais em caso de cadastro
    useEffect(() => {
        if(dados.id === null && dadosCliente !== null){
            consultarEventosEtapa();
        }
    }, [dadosCliente]);

    // define como cotação assim que adicionar o primeiro arquivo para upload
    useEffect(() => {
        if(autoMarcarCotacao && dados.id === null && dados.cotacao === 'N' && anexosFormulario.length > 0){
            dados.cotacao = 'S';
            alterarDados({...dados});
            alterarAutoMarcarCotacao(false);
        }
    }, [anexosFormulario]);
    
    // se estiver carregando os dados
    if(carregando){
        return <p
            className="text-center"
        >
            Carregando, aguarde <FontAwesomeIcon icon={faSpinner} pulse />
        </p>
    }
    
    return <FichaCadastrarContexto.Provider value={{
        dadosCliente, erros, salvando, carregandoEventosAutomatico,
        eventos, alterarEventos, eventosNovos, eventosRemover,
        dados, alterarDados,
        anexosFormulario, alterarAnexosFormulario,
        dadosProposta, alterarDadosProposta,
        dadosPropostaParcelas, alterarDadosPropostaParcelas,
        anexosProposta, alterarAnexosProposta,
        dadosEmissao, alterarDadosEmissao,
        dadosEmissaoParcelas, alterarDadosEmissaoParcelas,
        anexosEmissao, alterarAnexosEmissao,
        agendarSmsEmissao, alterarAgendarSmsEmissao,
        dadosVigencia, alterarDadosVigencia,
        idFichaRenovacao
    }}>
        <FichaComponent
            etapa={dados.etapa}
            propostaCancelado={dados.etapa === '3' && (dadosProposta && dadosProposta.cancelado === 'S')}
            emissaoCancelado={dados.etapa === '4' && (dadosEmissao && dadosEmissao.cancelado === 'S')}
        >          

            {!salvando && <Prompt
                when={
                    (
                        assinatura &&
                        (
                            assinatura.length !== JSON.stringify({ 
                                dados,
                                dadosCliente,
                                dadosProposta,
                                dadosPropostaParcelas,
                                dadosVigencia,
                                dadosEmissao,
                                dadosEmissaoParcelas,
                                agendarSmsEmissao,
                                eventosNovos,
                                eventosRemover,
                                anexosFormulario,
                                anexosProposta,
                                idFichaRenovacao,
                            }).length
                        )
                    )
                }
                message={'Deseja sair dessa tela sem salvar as alterações?'}
            />}

            <h4>Ficha de Atendimento</h4>

            {parseInt(dados.etapa) > 0 &&
                <div className="tag-etapa shadow-sm">
                    <>
                        {dados.etapa === '1' && `Cotação`}
                        {dados.etapa === '2' && `Renovação`}
                        {dados.etapa === '3' && `Proposta ${(dadosProposta && dadosProposta.cancelado === 'S') ? 'cancelada' : ''}`}
                        {dados.etapa === '4' && `Emissão ${(dadosEmissao && dadosEmissao.cancelado === 'S') ? 'cancelada' : ''}`}
                    </>
                </div>
            }

            <Row>
                <Col lg={6}>
                    <div className={'d-flex'}>
                        <div className="mr-2">
                            <Atalhos 
                                idCliente={id_cliente}
                            />
                        </div>
                        <div className="flex-grow-1">
                            <Card className='border-0 shadow-sm'>
                                <Card.Body>
                                    <Form.Group>
                                        <Form.Label>Responsável</Form.Label>
                                        <ComboResponsavel 
                                            id={dados.id_responsavel}
                                            alterou={(id) => {
                                                dados.id_responsavel = id;
                                                alterarDados({...dados});
                                            }}
                                        />
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </div>

                    </div>
                </Col>
                <Col lg={6}>
                    <DadosClienteFormulario />
                </Col>
            </Row>

            {/* Eventos da ficha */}
            <EventosFicha />

            {/* dados iniciais da ficha */}
            <Formulario 
                idFichaRenovar={id_ficha_renovar}
                clicouRenovar={renovar}
            />

            {dados.tipo === '2' && 
                <>
                    {/* dados da proposta */}
                    <Proposta />
        
                    {/* dados da emissão */}
                    <Emissao />
                </>
            }

            <Form.Group className="d-flex justify-content-around my-4">
                <Button variant="danger" onClick={() => {history.push(`/painel/clientes/alterar/${id_cliente}/fichas`)}}>
                    { (dados.id === null) ?
                        `Cancelar`: `Voltar`
                    }
                </Button>
                <Button 
                    variant="success" 
                    disabled={salvando || carregandoEventosAutomatico} 
                    onClick={salvar}
                >
                    {salvando ? 
                        <>
                            <FontAwesomeIcon className="icone" pulse icon={faSpinner} />
                            <span>Salvando</span>
                        </> : 
                        <span>Salvar</span>
                    }
                </Button>
            </Form.Group>

            {Object.values(erros).length > 0 &&
                <Row>
                    <Col lg={{span: 3, offset: 9}}>
                        <Alert variant="danger" className='border-0'>
                            <FontAwesomeIcon icon={faExclamationCircle} /> Houve alguns erros ao salvar, verifique e tente novamente!
                        </Alert>
                    </Col>
                </Row>
            }

            {salvandoAnexos &&
                <Row>
                    <Col lg={{span: 3, offset: 9}}>
                        <Alert variant="info" className='border-0'>
                            <FontAwesomeIcon icon={faSpinner} pulse /> Aguarde enquanto o upload dos anexos é feito!
                        </Alert>
                    </Col>
                </Row>
            }

        </FichaComponent>
    </FichaCadastrarContexto.Provider>

}