import React from 'react';
import { Button } from 'react-bootstrap';

function Tags({
    tipo='1',
    planoCliente=false
}){
    
    let tagsClientes = [
        {descricao: 'Nome', id: '[CL_NOME]'},
        {descricao: 'Primeiro Nome', id: '[CL_PRIMEIRO_NOME]'},
        {descricao: 'Nome Pai', id: '[CL_NOME_PAI]'},
        {descricao: 'Nome Mãe', id: '[CL_NOME_MAE]'},
        {descricao: 'Carta Oferta', id: '[CL_CARTA_OFERTA]'},
        {descricao: 'Número Apólice', id: '[CL_NUMERO_APOLICE]'},
        {descricao: 'Vigência da Apólice', id: '[CL_DATA_VIGENCIA_APOLICE]'},
        {descricao: 'Número do Contrato', id: '[CL_NUMERO_CONTRATO]'},
        {descricao: 'Valor', id: '[CL_SEGURO_VALOR_DIARIA]'},        
        {descricao: 'Valor morte acidental', id: '[CL_SEGURO_VALOR_MORTE_ACIDENTAL]'},
        {descricao: 'Valor invalidez', id: '[CL_SEGURO_VALOR_INVALIDEZ]'},
        {descricao: 'Valor DMHO', id: '[CL_SEGURO_VALOR_DMHO]'},        
        {descricao: 'Estado Civil', id: '[CL_ESTADO_CIVIL]'},
        {descricao: 'Profissão', id: '[CL_PROFISSAO]'},
        {descricao: 'CPF', id: '[CL_CPF]'},
        {descricao: 'RG', id: '[CL_RG]'},
        {descricao: 'Data de Emissão RG', id: '[CL_RG_DATA_EMISSAO]'},
        {descricao: 'CNH', id: '[CL_CNH]'},
        {descricao: 'Data de emissão CNH', id: '[CL_CNH_DATA_EMISSAO]'},
        {descricao: 'Data de Nascimento', id: '[CL_DATA_NASCIMENTO]'},
        {descricao: 'Telefone Residencial', id: '[CL_TELEFONE_RESIDENCIAL]'},
        {descricao: 'Telefone Comercial', id: '[CL_TELEFONE_COMERCIAL]'},
        {descricao: 'Telefone Celular', id: '[CL_TELEFONE_CELULAR]'},
        {descricao: 'Email', id: '[CL_EMAIL]'},
        {descricao: 'Nome Fantasia', id: '[CL_NOME_FANTASIA]'},
        {descricao: 'Juridico - Nome Responsável', id: '[CL_RESPONSAVEL_JURIDICO_NOME]'},
        {descricao: 'Juridico - Telefone Responsável', id: '[CL_RESPONSAVEL_JURIDICO_TELEFONE]'},
        {descricao: 'CNPJ', id: '[CL_CNPJ]'},
        {descricao: 'Juridico - Email Responsável', id: '[CL_RESPONSAVEL_JURIDICO_EMAIL]'},
        {descricao: 'Site', id: '[CL_SITE]'},
        {descricao: 'CEP', id: '[CL_CEP]'},
        {descricao: 'Cidade', id: '[CL_CIDADE]'},
        {descricao: 'Estado', id: '[CL_ESTADO]'},
        {descricao: 'Endereço', id: '[CL_ENDERECO]'},
        {descricao: 'Número', id: '[CL_NUMERO]'},
        {descricao: 'Bairro', id: '[CL_BAIRRO]'},
        {descricao: 'Complemento', id: '[CL_COMPLEMENTO]'},
    ];
    let tagsParcelaEmissao = [
        {descricao: 'Valor da parcela', id: '[PE_VALOR]'},
        {descricao: 'Valor dos juros da parcela', id: '[PE_VALOR_JUROS]'},
        {descricao: 'valor da parcela + juros', id: '[PE_VALOR_TOTAL]'},
        {descricao: 'Vencimento', id: '[PE_VENCIMENTO]'},
        {descricao: 'Razao Social Seguro', id: '[S_CL_NOME]'},
        {descricao: 'Nome Fantasia Seguro', id: '[S_CL_NOME_FANTASIA]'},
        {descricao: 'Forma de pagamento', id: '[FE_FORMA_PAGAMENTO]'},
        {descricao: 'Número da apólice', id: '[FE_NUMERO_APOLICE]'},
        {descricao: 'Data emitido', id: '[FE_DATA_EMITIDO]'},
    ];
    let tagsSeguroAventura = [
        {descricao: 'Código Referência', id: '[SA_CARTA_OFERTA]'},
        {descricao: 'Codigo', id: '[SA_CODIGO]'},
        {descricao: 'Local do evento', id: '[SA_LOCAL_EVENTO]'},
        {descricao: 'Nome do evento', id: '[SA_NOME_EVENTO]'},
        {descricao: 'Data de inicio evento', id: '[SA_DATA_INICIO]'},
        {descricao: 'Hora início evento', id: '[SA_HORA_INICIO]'},
        {descricao: 'Data final evento', id: '[SA_DATA_FINAL]'},
        {descricao: 'Hora final evento', id: '[SA_HORA_FINAL]'},
        // {descricao: 'Hora de inicio', id: '[SA_HORA_INICIO]'},
        // {descricao: 'Data final', id: '[SA_DATA_FINAL]'},
        // {descricao: 'hora final', id: '[SA_HORA_FINAL]'},
        {descricao: 'Responsavel', id: '[SA_RESPONSAVEL]'},
        {descricao: 'Telefone', id: '[SA_TELEFONE]'},
        {descricao: 'Email', id: '[SA_EMAIL]'},
        {descricao: 'Descrição do evento', id: '[SA_DESCRICAO]'},
        {descricao: 'Número da apólice', id: '[SA_NUMERO_APOLICE]'},

        // valores cliente
        {descricao: 'Valor morte acidental', id: '[CL_SEGURO_VALOR_MORTE_ACIDENTAL]'},
        {descricao: 'Valor invalidez', id: '[CL_SEGURO_VALOR_INVALIDEZ]'},
        {descricao: 'Valor DMHO' /* 'Despesas médico-hospitalares odontológicas' */, id: '[CL_SEGURO_VALOR_DMHO]'},

        // dados do participante
        {descricao: 'Nome do participante', id: '[SAP_NOME]'},
        {descricao: 'Data entrada participante', id: '[SAP_DATA_ENTRADA]'},
        {descricao: 'Data saída participante', id: '[SAP_DATA_SAIDA]'},
        // {descricao: 'Data nascimento do participante', id: '[SAP_DATA_NASCIMENTO]'},
        // {descricao: 'Telefone do participante', id: '[SAP_TELEFONE]'},
        // {descricao: 'Email do participante', id: '[SAP_EMAIL]'},
    ];

    // caso haver plano cliente para adicionar
    if(planoCliente) {
        // adiciona as tags
        tagsClientes.push({
            descricao: 'Plano', id: '[CL_PLANO]'
        })
    }

    return <>
        {(['1', '2', '3'].includes(tipo) && 
            <>
                {tagsClientes.map(opcao => 
                    <BotaoTag 
                        key={opcao.id} 
                        value={opcao.descricao}
                        onMouseDown={e => {
                            document.execCommand('insertText', false, opcao.id);
                        }}
                    />    
                )}
            </>
        )}

        {(['2'].includes(tipo) && 
            <>
                <hr />
                {tagsParcelaEmissao.map(opcao => 
                    <BotaoTag 
                        key={opcao.id} 
                        value={opcao.descricao}
                        onMouseDown={e => {
                            document.execCommand('insertText', false, opcao.id);
                        }}
                    />    
                )}
            </>
        )}

        {(['3'].includes(tipo) && 
            <>
                <hr />
                {tagsSeguroAventura.map(opcao => 
                    <BotaoTag 
                        key={opcao.id} 
                        value={opcao.descricao}
                        onMouseDown={e => {
                            document.execCommand('insertText', false, opcao.id);
                        }}
                    />    
                )}
            </>
        )}

    </>

}

// componete de botao para tag
function BotaoTag({
    value,
    onMouseDown
}){

    return <Button
        variant='padrao'
        size='sm'
        className='mr-2 mb-2'
        style={{
            fontSize: '0.8rem',
            fontWeight: 'normal'
        }}
        onMouseDown={e => {
            e.preventDefault();
            onMouseDown(e);
        }}
    >
        {value}
    </Button>
}

export default Tags;