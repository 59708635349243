import React, { 
    useState, 
    useEffect, 
    useContext 
} from 'react';
import { 
    Card, 
    ButtonToolbar, 
    OverlayTrigger, 
    Button, 
    Tooltip, 
    Form 
} from 'react-bootstrap';
import { 
    faPlus, 
    faTrash, 
    faPencilAlt, 
    faThumbtack, 
    faSpinner, 
    faClock, 
    faCalendarCheck 
} from '@fortawesome/free-solid-svg-icons';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import uniqid from 'uniqid';
import moment from 'moment';

// importa contexto
import { FichaCadastrarContexto } from './../FichaCadastrarContexto';

// componentes
import { ContainerComponent, EventoComponent } from './components';
import EventosModalCadastrar from './../../../Eventos/ModalCadastrar';

/**
 * Os eventos já cadastrados podem ser editados e esses serão salvos na hora
 * Os novos, só seram salvos quando clicado em salvar
 * o botão para salvar evento temporario deve ser (incluir)
 * o botão para salvar evento cadastrado deve ser (salvar)
 * 
 * Apresentar eventos que seram salvos junto com a ficha no container separado
 * Apresentar eventos que seram excluídos ao salvar a ficha no container separado
 * 
 * Mostrar toast em tudo que é feito
 */

// eventos da ficha
export default function EventosFicha(){

    // dados do componente principal
    const { 
        dados, 
        dadosCliente, 
        eventos, 
        alterarEventos, 
        eventosNovos, 
        eventosRemover, 
        salvando, 
        carregandoEventosAutomatico 
    } = useContext(FichaCadastrarContexto);
    const [parametros, alterarParametros] = useState({
        id_alvo: dados.id,
        alvo_tabela: 'clientes_fichas',
        dados_extras: 'S',
        concluido: ['N']
    });
    const [consultando, alterarConsultando] = useState(false);
    const [cadastrarEvento, alterarCadastrarEvento] = useState(null);

    // faz a consulta
    async function consultar(){
        alterarConsultando(true);

        try {

            // faz a requisição
            const { data } = await axios.get(`/eventos`, { params: parametros });
            alterarEventos(data.registros);

        } catch({ response }) {
            console.log(response);
        } finally {
            alterarConsultando(false);
        }
    }

    // exclui evento
    async function excluirEvento(eventoRemover) {
        if(eventoRemover.dados.id === null){
            let novoArrayEventos = eventos.filter(evento => evento !== eventoRemover);
            alterarEventos([...novoArrayEventos]);
        }
    }

    // inicializa
    useEffect(() => {
        if(parametros.id_alvo !== null){
            consultar();
        }
    }, [parametros, dados.data_edicao]);

    useEffect(() => {
        if(dados.id !== null && dados.id !== parametros.id_alvo){
            parametros.id_alvo = dados.id;
            alterarParametros({...parametros});
        }
    }, [dados.id]);

    return <>
        <ContainerComponent
            salvando={salvando}
        >
            <div className={'mr-2'}>
                <ButtonToolbar>
                    <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Adicionar Evento</Tooltip>}
                    >
                        <Button
                            variant='padrao'
                            size='sm'
                            onClick={() => {
                                alterarCadastrarEvento({
                                    id: null
                                });
                            }}
                            className='rounded-circle'
                            disabled={salvando}
                        >
                            <span className="fa-layers">
                                <FontAwesomeIcon icon={faPlus} size='xs' style={{ top: '-2px', left: '1px'}} />
                                <FontAwesomeIcon icon={faStickyNote} size='lg' />
                            </span>
                        </Button>
                    </OverlayTrigger>
                </ButtonToolbar>
            </div>
            <div className="flex-grow-1 coluna-eventos">
                <div className="m-0 text-secondary mb-1">
                    <div className='d-flex'>
                        <div className='flex-grow-1'>
                            <FontAwesomeIcon icon={faThumbtack} />
                            <span className='mx-2'>Eventos</span>
                            {(carregandoEventosAutomatico || consultando) &&
                                <small>
                                    <FontAwesomeIcon icon={faSpinner} pulse /> Carregando eventos
                                </small>
                            }
                        </div>
                        <div>
                            <div className="d-flex align-items-center">
                                <span className="mr-2">Concluídos?</span>
                                <Form.Check 
                                    custom
                                    type="checkbox"
                                    id="checkbox-filtro-concluidos-eventos-nao"
                                    label="Não"
                                    size="sm"
                                    className="mr-2"
                                    checked={parametros.concluido.includes('N')}
                                    onChange={e => {
                                        let novoValor = [];
                                        (e.target.checked && novoValor.push('N'));
                                        (parametros.concluido.includes('S') && novoValor.push('S'));
                                        alterarParametros({...parametros, concluido: novoValor});
                                    }}
                                />
                                <Form.Check 
                                    custom
                                    type="checkbox"
                                    id="checkbox-filtro-concluidos-eventos-sim"
                                    label="Sim"
                                    size="sm"
                                    className="mr-2"
                                    checked={parametros.concluido.includes('S')}
                                    onChange={e => {
                                        let novoValor = [];
                                        (e.target.checked && novoValor.push('S'));
                                        (parametros.concluido.includes('N') && novoValor.push('N'));
                                        alterarParametros({...parametros, concluido: novoValor});
                                    }}
                                />
                            </div> 
                        </div>
                    </div>
                </div>
                <Card className='border-0 shadow-sm'>
                    <Card.Body>
                        <div className="eventos">
                            <div className="eventos-container">

                                {eventosNovos.map(registro => 
                                    <EventoComponent key={registro.id} novo>
                                        <Card className={'border-0'}>
                                            <Card.Body className="p-1">
                                                <p className="descricao">
                                                    {registro.descricao}
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </EventoComponent>
                                )}

                                {eventosRemover.map(registro => 
                                    <EventoComponent key={registro.id} remover>
                                        <Card className={'border-0'}>
                                            <Card.Body className="p-1">
                                                <p className="descricao">
                                                    {registro.descricao}
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </EventoComponent>
                                )}

                                {eventos.map((registro) => 
                                    <EventoComponent 
                                        key={registro.dados.id || registro.temp_id}
                                        automatico={registro.dados.id_evento_padrao ? true : false}
                                    >
                                        <Card className={'bg-light border-0'}>
                                            <Card.Body className="p-1">
                                                <div className="cabecalho">
                                                    <span>
                                                        
                                                        {registro.dados.concluido === 'S' ?
                                                            <>
                                                                <FontAwesomeIcon icon={faCalendarCheck} className="icone-concluido mr-1" />
                                                                {moment(registro.dados.data_concluido).calendar()}
                                                                
                                                            </>
                                                            :
                                                            <>
                                                                <FontAwesomeIcon icon={faClock} className="mr-1" />
                                                                {moment(registro.dados.data_programada).calendar()}
                                                            </>
                                                        }
                                                    </span>
                                                    <div>
                                                        <Button
                                                            variant="padrao"
                                                            className="rounded-pill"
                                                            onClick={() => {
                                                                alterarCadastrarEvento(registro.dados);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon className="fa-fw" icon={faPencilAlt} />
                                                        </Button>
                                                        
                                                        {/* eventos automáticos não podem ser removidos porque seram criados novamente(concluir esses) */}
                                                        {(registro.dados.id_evento_padrao === null && registro.dados.tipo_vigencia === 'N') &&
                                                            <Button
                                                                variant="padrao"
                                                                className="rounded-pill ml-2"
                                                                onClick={() => {
                                                                    excluirEvento(registro);
                                                                }}
                                                                disabled={registro.dados.id !== null}
                                                            >
                                                                <FontAwesomeIcon className="fa-fw" icon={faTrash} />
                                                            </Button>
                                                        }
                                                    </div>
                                                </div>
                                                <p className="descricao">
                                                    {registro.dados.descricao}
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </EventoComponent>
                                )}
                                
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </ContainerComponent>

        {cadastrarEvento &&
            <EventosModalCadastrar 
                fechou={() => {
                    alterarCadastrarEvento(null);
                }}
                salvou={dadosEvento => {
                    // alterarEventos([...eventos, dadosEvento]);
                    let eventoAlterado = eventos.find(evento => {
                        if(evento.dados === dadosEvento){
                            return evento;
                        }
                        return false;
                    });

                    // verifica se é um evento novo, ou recem cadastrado
                    if(eventoAlterado){
                        Object.assign(eventoAlterado.dados, dadosEvento);
                        alterarEventos([...eventos]);
                    }else{
                        alterarEventos([{dados: dadosEvento, temp_id: uniqid()}, ...eventos]);
                    }

                }}
                registro={cadastrarEvento}
                idAlvo={dados.id}
                alvoTabela={'clientes_fichas'}
                preCliente={dados.etapa === '4' ? 'N' : dadosCliente.pre_cliente}
                idResponsavel={dados.id_responsavel}
            />
        }
    </>

}