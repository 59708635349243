import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Button,
    Col,
    Modal,
    Container,
    Row,
    Form
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import style from "./index.module.scss";
import { useDispatch } from 'react-redux';


import logoTipoAventura from './../../../../assets/rocaSeguroAventura.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function Consultar() {

    // busca
    const { search } = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    
    const [buscar, alterarBuscar] = useState("");
    const [carregando, alterarCarregando] = useState(false);
    const [codigo, alterarCodigo] = useState('');
    const [modalLembrar, alterarModalLembrar] = useState(false);
    const [solicitandoApolice, alterarSolicitandoApolice] = useState(false);
    const [errosSolicitacao, alterarErrosSolicitacao] = useState(null);
    const [campoSolicitar, alterarCampoSolicitar] = useState("");    
    
    // buscar código
    async function consultarCodigo() {
        alterarCarregando(true);

        try {

            // faz a requisição
            let { data } = await axios.post(`/seguroAventura/codigo`, { codigo: codigo });
            history.push(`/formularios/propostaSeguro/evento/${data.dados.token}`);

        } catch ({ response }) {
            toast(({ closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Código não encontrado!</strong>
                    <button
                        onClick={closeToast}
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>);
        } finally {
            alterarCarregando(false);
        }
    }

    // faz a busca da apólice
    async function buscarApolice() {

        // bota pra carregar
        alterarCarregando(true);

        const cartaOfertaBusca = buscar;

        try {

            // faz a requisição
            const { data } = await axios
                .post(`/seguroAventura/cartaOferta`, 
                    { 
                        cartaOferta: cartaOfertaBusca 
                    }
                )
            ;

            // limpa parametros na url
            history.replace({
                search: ''
            });

            // verifica se gerou token
            if (data.jwt) {

                axios.defaults.headers.common['Authorization'] = data.jwt;

                // salva no navegador para não precisar consultar novamente
                dispatch({
                    type: 'cartaOferta/ATUALIZAR',
                    dados: data
                });

            }

        } catch ({ response }) {

            // caso retornou erro
            if(response?.data && Object.keys(response.data)) {

                // resgata primeira chave do erro
                const erroChave = Object.keys(response.data)[0];
                
                // mensagem de erro
                toast(({ closeToast }) => <>
                    <div
                        style={{ background: '#ff6271' }}
                    >
                        <div className="toast-header">
                            <strong className="mr-auto">Código não encontrado!</strong>
                            <button
                                onClick={closeToast}
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                            </button>
                        </div>
                        <div className="toast-body text-light">
                            <p>{response.data[erroChave]}</p>                            
                        </div>
                    </div>
                </>, { autoClose: 5000 });

            } else {
            
                // mostra mensagem de erro
                toast(({ closeToast }) => <>
                    <div
                        style={{ background: '#ff6271' }}
                    >
                        <div className="toast-header">
                            <strong className="mr-auto">Código não encontrado!</strong>
                            <button
                                onClick={closeToast}
                                className="ml-2 mb-1 close btn-outline-light outline-0"
                            >
                            </button>
                        </div>
                        <div className="toast-body text-light">
                            <p>Por favor, tente novamente</p>
                        </div>
                    </div>
                </>, { autoClose: 5000 });

            }

        } finally {
            alterarCarregando(false);
        }

    }

    // lembrar código de apólice
    async function lembrarApolice() {

        // ativa carregamento
        alterarSolicitandoApolice(true);

        try {

            // faz a requisição
            await axios.post(`/seguroAventura/lembrarCartaOferta`, {
                busca: campoSolicitar
            });

            toast(({ closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Solicitado!</strong>
                    <button
                        onClick={closeToast}
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Verifique seu email e seu SMS!
                </div>
            </>);

            alterarCampoSolicitar(null);
            alterarModalLembrar(false);

        } catch ({ response }) {
            if (response && response.status && response.status === 400) {
                alterarErrosSolicitacao(response.data);
            }
        } finally {
            alterarSolicitandoApolice(false);
        }


    }

    return <div className="vh-100" style={{ backgroundColor: "#595959" }}>
        <Container className="pt-5">
            <Row>
                <Col lg={{ span: 6, offset: 3 }}>
                    <div>
                        <div className="logo text-center mb-n5 z-index-1 position-relative" style={{ zIndex: 1 }}>
                            <img src={logoTipoAventura} className="w-75 logo-roca" />
                        </div>
                        <div className="d-flex align-items-center justify-content-center ">
                            <div className={["border-0 bg-transparent pt-5 text-white w-75", style.bgRoca].join(` `)}>
                                <div >
                                    <div className={style.apresentar}>
                                        <p className={style.titulo}>Olá :)</p>
                                        <p>pode contar com a nossa proteção nas suas aventuras</p>

                                        <p>vamos começar?</p>
                                    </div>
                                    <div className={[style.apolice, `d-flex align-items-center`].join(` `)}>
                                        <Form.Control
                                            className={`flex-grow-1`}
                                            placeholder={`código de parceiro`}
                                            disabled={carregando}
                                            value={buscar}
                                            onChange={(e) => {
                                                alterarBuscar(e.target.value);
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') buscarApolice()
                                            }}
                                        />
                                        <Button
                                            variant="default"
                                            className={style.botaoEntrar}
                                            onClick={() => buscarApolice()}
                                            disabled={carregando}
                                        >
                                            <span>Entrar</span>
                                            <FontAwesomeIcon
                                                className="ml-2"
                                                icon={carregando ? faSpinner : faSearch}
                                                pulse={carregando}
                                            />
                                        </Button>
                                    </div>

                                    <div className={[style.lembrarCodigo, "mt-4"].join(` `)}>
                                        <Button
                                            variant="default"
                                            onClick={() => {
                                                alterarModalLembrar(true);
                                            }}
                                        >Esqueci meu código</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>

        <Modal
            show={modalLembrar}
            onExited={() => {
                alterarModalLembrar(false);
                alterarErrosSolicitacao(null);
            }}
            onHide={solicitandoApolice ? undefined : () => {
                alterarModalLembrar(false);
            }}
            size={'sm'}
        >
            <Modal.Body
                className={[style.bgRoca, style.bgRocaModal].join(' ')}
            >
                <p class="">Informe seu email, cpf ou cnpj sem pontuação.</p>
                <Form.Control
                    value={campoSolicitar}
                    onChange={e => {
                        alterarCampoSolicitar(e.target.value);
                    }}
                />
                <div
                    className="d-flex justify-content-center mt-2"
                >
                    <Button
                        variant="default"
                        className={style.botaoSolicitar}
                        onClick={lembrarApolice}
                        disabled={solicitandoApolice}
                    >
                        <span>Solicitar</span>
                        <FontAwesomeIcon
                            className="ml-2"
                            icon={solicitandoApolice ? faSpinner : faSearch}
                            pulse={solicitandoApolice}
                        />
                    </Button>
                </div>
                {errosSolicitacao &&
                    <p className="mt-2">{errosSolicitacao?.cartaOfertaNaoEncontrada || errosSolicitacao?.seguroAventura || ''}</p>
                }
            </Modal.Body>
        </Modal>

    </div>
}