import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Card } from 'react-bootstrap';
import Flatpick from 'react-flatpickr';
import { Portuguese } from "flatpickr/dist/l10n/pt";
import moment from 'moment';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// componentes
import Aniversariantes from './Aniversariantes';
import Eventos from './Eventos';
import ComboResponsavel from './../Componentes/ComboResponsavel';

// eventos
function PainelInicio({
    login
}){

    // data
    const [data, alterarData] = useState(moment().format('YYYY-MM-DD'));
    const [idResponsavel, alterarIdResposavel] = useState(login.dadosUsuario.id);

    return <>
        <div>
            {/* [usuário ao qual os eventos se referem] [data inicial de consulta] */}
        </div>
        <Row>
            <Col md='9'>
                <Card className='border-0 mb-3 shadow-sm'>
                    <Card.Body>
                        <Row className={styles.filtros}>
                            <Col md='5'>
                                <ComboResponsavel 
                                    id={idResponsavel}
                                    alterou={(id) => {
                                        alterarIdResposavel(id);
                                    }}
                                />
                            </Col>
                            <Col md={{ span: 4, offset: 3 }}>
                                <div className={[styles.campoDataContainer, 'rounded bg-light px-2'].join(' ')}>
                                    <FontAwesomeIcon className={styles.icone} icon={["fas", 'calendar-day']} />
                                    <Flatpick 
                                        className={["form-control", styles.campoData].join(' ')} 
                                        value={data}
                                        options={{
                                            dateFormat: "Y-m-d",
                                            altFormat: "d/m/Y",
                                            altInput: true,
                                            locale: Portuguese,
                                            // allowInput: true,
                                            onChange: (date, dateStr, instance) => {
                                                if(dateStr === '') dateStr = null;
                                                alterarData(dateStr);
                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Eventos 
                    idResponsavel={idResponsavel}
                    data={data}
                />
            </Col>
            <Col md='3'>
                <div>
                    <Aniversariantes 
                        dataBase={data}
                    />
                </div>
            </Col>
        </Row>
    </>

}

// importa states
let mapStateToProps = function(state){
    let estado = {
        login: state.login
    };
    return estado;
};

// retorna página de consulta
export default connect(mapStateToProps)(PainelInicio);
