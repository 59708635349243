import React, { 
    memo,
    useState,
    useEffect,
    useMemo
} from 'react';
import {
    Card,
    Form,
    Col,
    Button,
    Alert,
    Badge
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaskedInput from 'react-maskedinput';
import moment from 'moment';
import axios from 'axios';

import {
    faSpinner,
    faTrash,
    faEnvelope,
    faHeart
} from '@fortawesome/free-solid-svg-icons';
import { ParticipanteComponent } from './components';
import InputData from './../../../../Painel/Componentes/InputData';
import BotaoTabela from './../../../../Painel/Componentes/BotaoTabela';
import ModalConfirmar from '../../../../Painel/Componentes/ModalConfirmar';
import BotaoCancelar from './BotaoCancelar';

function Participante({
    dados,
    tempId,
    participanteImportadoXls=false,
    seguroDados,
    habilitaCancelamento,
    alterarDados,
    erros,
    excluir,
    seguroDadosIniciais,
    leituraParticipante
}) {

    // estados
    const [leitura, alterarLeitura] = useState(dados.cancelado === 'S' || leituraParticipante);
    const [erroDestaque, alterarErroDestaque] = useState(Object.keys(erros).length > 0);
    const [consultandoCpf, alterarConsultandoCpf] = useState(false);
    const [gerandoPdf, alterarGerandoPdf] = useState(false);
    const [carregamentoEmail, alterarCarregamentoEmail] = useState(false);
    const [mostrarModal, alterarMostarModal] = useState(false);
    const [mostrarContatoEmergencia, alterarMostrarContatoEmergencia] = useState(
        (dados.contatoEmergenciaNome || dados.contatoEmergenciaTelefone) ? true : false
    );
    const [mostrarDadosSaude, alterarMostrarDadosSaude] = useState(
        dados?.restricoesDadosSaude ? true : false
    );
    
    // referencias
    const telefoneInput = React.createRef();
    const telefoneEmergenciaInput = React.createRef();
    let cpfInput = React.createRef();    

    const isMobile = useMemo(() => {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }, []);
    
    // formata telefone quando estiver no mobile
    const formatarTelefone = (valor) => {
        // remove tudo que não for número
        const numeros = valor.replace(/\D/g, '');
    
        // aplica a máscara manualmente
        if (numeros.length === 11) {
          return `(${numeros.slice(0, 2)}) ${numeros.slice(2, 7)}-${numeros.slice(7)}`;
        } else if (numeros.length === 10) {
          return `(${numeros.slice(0, 2)}) ${numeros.slice(2, 6)}-${numeros.slice(6)}`;
        }
    
        // retorna valor original se não conseguir formatar
        return valor;
    };

    // consulta cpf de participante já cadastrado
    async function consultarCpf() {

        // ativa carregamento
        alterarConsultandoCpf(true);

        try {

            // faz a requisição
            const { data } = await axios.get(`/seguroAventuraParticipante/cpf`, {
                params: {
                    cpf: dados.cpf
                }
            });

            // altera os dados
            Object.assign(dados, {
                nome: data.dados.nome,
                dataNascimento: data.dados.dataNascimento,
                email: data.dados.email,
                telefone: data.dados.telefone,
                contatoEmergenciaTelefone: data.dados.contatoEmergenciaTelefone,
                contatoEmergenciaNome: data.dados.contatoEmergenciaNome,
                restricoesDadosSaude: data.dados.restricoesDadosSaude
            });

            // verifica para ativar checkbox
            alterarMostrarContatoEmergencia((dados.contatoEmergenciaNome || dados.contatoEmergenciaTelefone) ? true : false);
            alterarMostrarDadosSaude(dados?.restricoesDadosSaude ? true : false);

        } catch ({ response }) {
            console.error(response);
        } finally {
            alterarConsultandoCpf(false);
        }

    }

    // faz o envio do email
    async function reenviarEmail() {

        // mostra carregamento
        alterarCarregamentoEmail(true);

        try {

            // faz a requisição
            await axios.post(`/seguroAventura/enviarEmailParticipante`, {
                id: seguroDados.id,
                participante: dados,
                anexoComprovanteContratacao: "S"
            });

            // esconde modal
            alterarMostarModal(false);

            // mensagem de cadastro realizado com sucesso
            toast(({ closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Email enviado!</strong>
                    <button
                        onClick={closeToast}
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>, { autoClose: 5000 });

        } catch (e) {
            console.error(e);
        } finally {

            // esconde carregamento
            alterarCarregamentoEmail(false);

        }

    }

    // baixa comprovante de contração do participante
    async function baixarComprovanteParticipante() {

        // ativa o carregamento do download
        alterarGerandoPdf(true);

        try {

            // gera pdf
            const { data } = await axios.get(`/seguroAventura/pdfComprovanteContratacao/${seguroDados.token}`, {
                params: {
                    idParticipante: dados.id
                }                
            });
            
            // faz o download
            window.open(
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=anexosEmail&f=${encodeURIComponent(data.temporario)}&n=${encodeURIComponent(data.nome)}`, 
                '_blank'
            );

        } catch({ response }) {

            console.error(response);

            toast(<>
                <div
                    style={{ background: '#ff6271' }}
                >
                    <div className="toast-header">
                        <strong className="mr-auto">Erro!</strong>
                        <button
                            className="ml-2 mb-1 close btn-outline-light outline-0"
                        >
                        </button>
                    </div>
                    <div className="toast-body text-light">
                        Houve um erro ao realizar o download do comprovante
                    </div>
                </div>
            </>);

        } finally {

            // desativa carregamento
            alterarGerandoPdf(false);
        }

    }

    // faz verificações para ver se mostra ou não a opção de favoritar
    function mostrarOpcaoFavorito() {

        // se for estrangeiro
        if (dados.estrangeiro === "S") {

            // se numero de passaporte estiver vazio
            if (!dados.numeroPassaporte || dados.numeroPassaporte === "") return false;

        } else {

            // se cpf estiver vazio ou se cpf for composto zeros
            if (!dados.cpf || dados.cpf === "" || dados.cpf === "00000000000") return false;

        }

        // mostrará
        return true;

    }

    // sempre que o cpf for alterado
    useEffect(() => {

        // se preenchido faz a consulta do cpf
        if (!participanteImportadoXls && dados.cpf !== null && dados.cpf.length === 11 && dados.id === null) {
            consultarCpf();
        }

    }, [dados.cpf]);

    useEffect(() => {

        // habilita destaque
        alterarErroDestaque(Object.keys(erros).length > 0);

        // se tiver o erro
        if(erros?.contatoEmergenciaNome || erros?.contatoEmergenciaTelefone) {
            
            // habilita checkbox para mostrar o campo
            alterarMostrarContatoEmergencia(true);
        }

        // se tiver o erro
        if(erros?.restricoesDadosSaude) {
            
            // habilita checkbox para mostrar o campo
            alterarMostrarDadosSaude(true);
        }
    }, [erros]);
    
    return <>
        <ParticipanteComponent
            id={`id_participante_${dados.id ?? tempId}`}
            cancelado={dados.cancelado === 'S'}
        >
            <Card 
                className={['shadow-sm', erroDestaque ? 'border-danger' : 'border-0'].join(' ')}
                style={erroDestaque ? { borderWidth: 2 } : {}}
                onClick={() => alterarErroDestaque(false)}
            >
                <Card.Body>
                    {seguroDadosIniciais.integrado === "S" &&
                        <div>
                            <p>Entrada <Badge variant="info">{moment(dados.dataEntrada).format('DD/MM/YYYY')}</Badge> com saída em <Badge variant="info">{moment(dados.dataSaida).format('DD/MM/YYYY')}</Badge></p>
                        </div>
                    }

                    {seguroDadosIniciais.integrado === "N" &&
                        <>
                            {(erros.dataNascimento || (dados.dataNascimento && dados.dataNascimento.length === 10 && moment().diff(dados.dataNascimento, 'years') <= 14)) &&
                                <Alert variant="danger" className="aviso-data p-1 border-0">
                                    Para os menores de 14 anos a cobertura de Morte Acidental estará limitada ao reembolso de despesas com o funeral.
                                </Alert>
                            }
                        </>
                    }
                    <Form.Row>
                        <Col lg={8}>
                            <Form.Group>
                                <Form.Label className="d-flex align-items-center mt-1">
                                    <span className=" flex-grow-1">
                                        {dados.estrangeiro === "N" ? "CPF*" : "Número Passaporte*"}
                                        {consultandoCpf &&
                                            <FontAwesomeIcon className="ml-1" icon={faSpinner} pulse />
                                        }

                                    </span>
                                    <Form.Check
                                        label="É estrangeiro?"
                                        id={`checkbox-participante-estrangeiro-${dados.numeroInscricao}`}
                                        type='checkbox'
                                        onChange={e => {
                                            dados.estrangeiro = e.target.checked ? "S" : "N";
                                            alterarDados({ ...dados });
                                        }}
                                        checked={dados.estrangeiro === "S"}
                                    />
                                </Form.Label>

                                {dados.estrangeiro === "N" &&
                                    <>
                                        <MaskedInput
                                            className={[
                                                "form-control form-control-sm",
                                                (erros.cpf) && 'is-invalid'
                                            ].join(' ')}
                                            mask="111.111.111-11"
                                            value={dados.cpf}
                                            ref={cpfInput}
                                            placeholder='000.000.000-00'
                                            onChange={(e) => {
                                                dados.cpf = cpfInput.current.mask.getRawValue().split('_').join('');
                                                alterarDados({ ...dados });
                                            }}
                                            readOnly={leitura}
                                        />
                                        {(erros.cpf) &&
                                            <Form.Control.Feedback type="invalid">{erros.cpf}</Form.Control.Feedback>
                                        }
                                    </>
                                }

                                {dados.estrangeiro === "S" &&
                                    <>
                                        <Form.Control
                                            value={dados.numeroPassaporte || ''}
                                            maxLength='50'
                                            onChange={e => {
                                                alterarDados({ ...dados, numeroPassaporte: e.target.value })
                                            }}
                                            size="sm"
                                            isInvalid={erros.numeroPassaporte}
                                            readOnly={leitura}
                                        />
                                        {erros.numeroPassaporte &&
                                            <Form.Control.Feedback type="invalid">
                                                {erros.numeroPassaporte}
                                            </Form.Control.Feedback>
                                        }
                                    </>
                                }
                            </Form.Group>

                        </Col>
                        <Col
                            className='d-flex align-items-start justify-content-end'
                        >
                            {mostrarOpcaoFavorito() && <BotaoTabela
                                color={'red'}
                                icone={dados.favorito === "S" ? faHeart : ["far", "heart"]}
                                tamanhoIcone={'2x'}
                                onClick={(e) => {

                                    // prepara dados para alterar
                                    let dadosAlterar = { ...dados };

                                    // seta favorito
                                    dadosAlterar.favorito = dados.favorito === "S" ? "N" : "S";

                                    // verifica se está desfavoritando
                                    if (dados.favorito === "S") {
                                        dadosAlterar['desfavoritar'] = "S";
                                    }

                                    // muda dados do participante
                                    alterarDados(dadosAlterar);

                                }}
                            >
                                {
                                    dados.favorito === "S" ?
                                        "Está incluído em todos os novos eventos" :
                                        "Será incluído em todos os novos eventos"
                                }
                            </BotaoTabela>}
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        {seguroDadosIniciais.integrado === "N" &&
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label title="Número Inscrição">n<sup>o</sup></Form.Label>
                                    <Form.Control
                                        value={dados.numeroInscricao || ''}
                                        maxLength='50'
                                        onChange={e => {
                                            alterarDados({ ...dados, numeroInscricao: e.target.value })
                                        }}
                                        isInvalid={erros.numeroInscricao}
                                        size="sm"
                                        readOnly={leitura}
                                    />
                                    {erros.numeroInscricao &&
                                        <Form.Control.Feedback type="invalid">
                                            {erros.numeroInscricao}
                                        </Form.Control.Feedback>
                                    }
                                </Form.Group>
                            </Col>
                        }
                        <Col lg={seguroDadosIniciais.integrado === "N" ? 6 : 8}>
                            <Form.Group>
                                <Form.Label>Nome*</Form.Label>
                                <Form.Control
                                    value={dados.nome || ''}
                                    maxLength='100'
                                    onChange={e => {
                                        alterarDados({ ...dados, nome: e.target.value })
                                    }}
                                    size="sm"
                                    isInvalid={erros.nome}
                                    readOnly={leitura}
                                />
                                {erros.nome &&
                                    <Form.Control.Feedback type="invalid">
                                        {erros.nome}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group>
                                <Form.Label>Nascimento*</Form.Label>
                                <InputData
                                    data={dados.dataNascimento}
                                    onChange={(data) => {
                                        alterarDados({ ...dados, dataNascimento: data });
                                    }}
                                    size="sm"
                                    isInvalid={erros.dataNascimento}
                                    readOnly={leitura}
                                />
                                {erros.dataNascimento &&
                                    <Form.Control.Feedback type="invalid">
                                        {erros.dataNascimento}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    {dados.estrangeiro === "S" &&
                        <Form.Group>
                            <Form.Label>Nacionalidade</Form.Label>
                            <Form.Control
                                value={dados.nacionalidade || ''}
                                maxLength='50'
                                onChange={e => {
                                    alterarDados({ ...dados, nacionalidade: e.target.value })
                                }}
                                size="sm"
                                isInvalid={erros.nacionalidade}
                                readOnly={leitura}
                            />
                            {erros.nacionalidade &&
                                <Form.Control.Feedback type="invalid">
                                    {erros.nacionalidade}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                    }

                    <Form.Row>
                        <Col lg={8}>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    value={dados.email || ''}
                                    maxLength='100'
                                    isInvalid={erros.email}
                                    onChange={e => {
                                        alterarDados({ ...dados, email: e.target.value });
                                    }}
                                    size="sm"
                                />
                                {erros.email &&
                                    <Form.Control.Feedback type="invalid">
                                        {erros.email}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group>
                                <Form.Label>Telefone</Form.Label>
                                {(!isMobile) ? 
                                    <MaskedInput
                                        className={["form-control form-control-sm", erros.telefone ? 'is-invalid' : ''].join(` `)}
                                        mask="(11) 11111-1111"
                                        value={dados.telefone || ''}
                                        ref={telefoneInput}
                                        type='tel'
                                        onChange={(e) => {
                                            if(telefoneInput.current?.mask) {
                                                dados.telefone = telefoneInput.current.mask.getRawValue().split('_').join('');
                                            } else {
                                                dados.telefone = e.target.value;
                                            }
                                            alterarDados({ ...dados });
                                        }}        
                                        onPasteCapture={async (e) => {
                                            e.preventDefault();

                                            if(leitura) return;

                                            // resgata texto que está colado
                                            let textoColado = await navigator.clipboard.readText();

                                            if(typeof textoColado !== "string") return;

                                            // remove caracteres especiais e deixa somente numeros
                                            textoColado = textoColado.replace(/\s+/g, '').replace(/\D/g, '');

                                            // monta mascara
                                            const mascara = '11111111111';
                                            
                                            // prepara valor formatado
                                            let novoValor = '';
                                            let j = 0;

                                            // percorre se baseando pela mascara
                                            for (let i = 0; i < mascara.length; i++) {

                                                // resgata valor atual
                                                const valor = textoColado[j++];
                                                
                                                // verifica se é numero e insere a variavel
                                                if(!isNaN(valor)) novoValor += valor;
                                                
                                            }

                                            // altera dados do telefone
                                            dados.telefone = novoValor.split('_').join('').replace(/\s+/g, '');
                                            alterarDados({ ...dados });
                                        }}
                                        readOnly={leitura}
                                    />
                                    :
                                    <input 
                                        type="tel"
                                        className={["form-control form-control-sm", erros.telefone ? 'is-invalid' : ''].join(` `)}
                                        placeholder="(__) _____-____"
                                        value={dados.telefone || ''}
                                        onChange={(e) => alterarDados({ ...dados, telefone: e.target.value })}
                                        onBlur={(e) => {
                                            const formatado = formatarTelefone(e.target.value);
                                            alterarDados({ ...dados, telefone: formatado });
                                        }}
                                        maxLength={11}
                                    />
                                }
                                {erros.telefone &&
                                    <Form.Control.Feedback type="invalid">
                                        {erros.telefone}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row className={'mb-2'}>    
                        <Col>                    
                            <Form.Check
                                label="Informar contato emergência?"
                                id={`checkbox-participante-contato-emergencia-${dados.numeroInscricao}`}
                                type='checkbox'
                                onChange={(e) => {
                                    
                                    // resgata novo valor
                                    const mostrar = !mostrarContatoEmergencia;

                                    // altera valor do check box
                                    alterarMostrarContatoEmergencia(mostrar);

                                    // se está escondendo
                                    if(!mostrar) {

                                        // remove dados de emergencia
                                        alterarDados({
                                            ...dados,
                                            contatoEmergenciaTelefone: null,
                                            contatoEmergenciaNome: null
                                        });

                                    }
                                    
                                }}
                                className='d-flex'
                                checked={mostrarContatoEmergencia}
                            />
                        </Col> 
                        <Col>                    
                            <Form.Check
                                label="Informar restrições e dados de saúde?"
                                id={`checkbox-participante-restricoes-saude-${dados.numeroInscricao}`}
                                type='checkbox'
                                className='d-flex'
                                onChange={(e) => {
                                    
                                    // resgata novo valor
                                    const mostrar = !mostrarDadosSaude;

                                    // altera valor do check box
                                    alterarMostrarDadosSaude(mostrar);

                                    // se está escondendo
                                    if(!mostrar) {

                                        // remove dados de emergencia
                                        alterarDados({
                                            ...dados,
                                            restricoesDadosSaude: null
                                        });

                                    }
                                    
                                }}
                                checked={mostrarDadosSaude}
                            />
                        </Col>                       
                    </Form.Row>
                    {mostrarContatoEmergencia ? <Form.Row>                        
                        <Col lg={8}>
                            <Form.Group>
                                <Form.Label>
                                    Nome
                                </Form.Label>
                                <Form.Control
                                    value={dados.contatoEmergenciaNome || ''}
                                    maxLength='50'
                                    onChange={e => {
                                        alterarDados({ ...dados, contatoEmergenciaNome: e.target.value })
                                    }}
                                    size="sm"
                                    isInvalid={erros.contatoEmergenciaNome}
                                    readOnly={leitura}
                                />
                                {erros.contatoEmergenciaNome &&
                                    <Form.Control.Feedback type="invalid">
                                        {erros.contatoEmergenciaNome}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group>
                                <Form.Label>
                                    Telefone
                                </Form.Label>
                                <MaskedInput
                                    className={[
                                        "form-control form-control-sm",
                                        (erros.contatoEmergenciaTelefone) && 'is-invalid'
                                    ].join(' ')}
                                    mask="(11) 11111-1111"
                                    value={dados.contatoEmergenciaTelefone || ''}
                                    ref={telefoneEmergenciaInput}
                                    onChange={(e) => {
                                        dados.contatoEmergenciaTelefone = telefoneEmergenciaInput.current.mask.getRawValue().split('_').join('')
                                        alterarDados({ ...dados });
                                    }}
                                    readOnly={leitura}
                                />
                                {(erros.contatoEmergenciaTelefone) &&
                                    <Form.Control.Feedback type="invalid">{erros.contatoEmergenciaTelefone}</Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Col>
                    </Form.Row> : <></>}
                    {mostrarDadosSaude ? <Form.Row>                        
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Restrições e dados de saúde
                                </Form.Label>
                                <Form.Control
                                    value={dados.restricoesDadosSaude || ''}
                                    onChange={e => {
                                        alterarDados({ ...dados, restricoesDadosSaude: e.target.value })
                                    }}
                                    size="sm"
                                    as={'textarea'}
                                    rows={3}
                                    readOnly={leitura}
                                    isInvalid={erros.restricoesDadosSaude}
                                />
                                {erros.restricoesDadosSaude &&
                                    <Form.Control.Feedback type="invalid">
                                        {erros.restricoesDadosSaude}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Col>
                    </Form.Row> : <></>}
                    <div className="d-flex align-items-center">
                        <div className="opcoes d-flex p-2 flex-grow-1 text-right justify-content-end" style={{ gap: "10px" }}>
                            {(seguroDadosIniciais.finalizado === 'N') &&
                                <Button
                                    size="sm"
                                    variant="danger"
                                    className="rounded-pill"
                                    onClick={excluir}
                                >
                                    <FontAwesomeIcon className="mr-2" icon={faTrash} />
                                    <span>Excluir</span>
                                </Button>
                            }

                            <BotaoTabela 
                                carregando={gerandoPdf}
                                onClick={baixarComprovanteParticipante}
                                icone={["fas", "file-pdf"]}
                                tamanhoIcone={'2x'}
                                color={'#AA0000'}
                            >
                                Baixar Comprovante de Contratação Individual
                            </BotaoTabela>

                            {(dados.id && dados.email) && <Button
                                size="sm"
                                variant="info"
                                className="rounded-pill"
                                onClick={() => alterarMostarModal(true)}
                            >
                                <FontAwesomeIcon className="mr-2" icon={faEnvelope} />
                                <span>Reenviar-email</span>
                            </Button>}

                            {habilitaCancelamento &&
                                <BotaoCancelar
                                    id={dados.id}
                                    salvou={() => {
                                        alterarDados({ ...dados, cancelado: 'S' })
                                    }}
                                />
                            }
                        </div>
                    </div>
                    {dados.cancelado === 'S' &&
                        <p className="text-danger">
                            Cancelado!
                        </p>
                    }
                </Card.Body>
            </Card>
        </ParticipanteComponent>

        <ModalConfirmar
            mostrar={mostrarModal}
            titulo={'Reenviar email'}
            texto={`Você deseja reenviar o email para o participante ${dados.nome}?`}
            clicouCancelar={() => alterarMostarModal(false)}
            clicouConfirmar={() => reenviarEmail()}
            carregando={carregamentoEmail}
        />

    </>

}

export default memo(Participante);