import React, { 
    useState, 
    useEffect 
} from 'react';
import AsyncSelect from 'react-select/lib/Async';
import axios from 'axios';

// componentes
import OptionSelect from './OptionSelect';

// componente
export default function ComboPlanos({
    id,
    alterou
}){

    // estados
    const [consulta, alterarConsulta] = useState([]);
    const [carregando, alterarCarregando] = useState(true);
    const [dados, alterarDados] = useState(null);   
    const [montado, alterarMontado] = useState(false);
    
    // consulta os planos
    async function consultar(inputValue, callback = null){

        // ativa o carregamento
        alterarCarregando(true);

        try {

            // faz a requisição
            const { data } = await axios.get(`/plano`, {
                params: {
                    texto: inputValue
                }
            });

            // retorno
            alterarConsulta(data.registros);
            if(callback !== null){
                callback(data.registros)
            }

        } catch({ response }) {
            console.error(response);
        } finally {
            alterarCarregando(false);
        }
    }

    // consulta o id
    async function consultarId(){

        // carregando
        alterarCarregando(true);

        try {

            // faz a requisição
            const { data } = await axios.get(`/plano/${id}`);
            alterarDados(data.dados);     
            alterou(data.dados);       

        } catch({response}) {
            console.error(response);
        } finally {
            alterarCarregando(false);
        }
        
    }

    // consulta o id do modelo de email
    useEffect(() => {

        // carrega alguns
        consultar('');

        if(id){
            consultarId();
        }else{
            alterarCarregando(false);
        }

        alterarMontado(true);

    }, []);

    // se estiver carregando
    if(carregando || !montado){
        return <div>Carregando...</div>
    }
    
    return <AsyncSelect 
        isDisabled={carregando}
        isLoading={carregando}
        isClearable
        loadOptions={consultar}     // ao alterar, carrega
        defaultOptions={consulta}   // lista pré carregada
        value={dados && {
            label: `${dados.nome} ` + `${dados.valor ? `(R$ ${(dados.valor).replace('.', ',')})` : ''}`
        }}  
        onChange={(e) => {           
            if(!e) {
                alterarDados(null);
                alterou(null);
            } else {
                alterarDados(e.dados);
                alterou(e.dados);
            }
        }}
        components={{
            Option: OptionSelect
        }}
        noOptionsMessage={() => 'Digite algo para fazer a consulta'}
        loadingMessage={() => 'Carregando...'}
        placeholder={'Plano'}
    />

}
