import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Route, withRouter, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nota from './Nota';
import EventosModalCadastrar from './../../Eventos/ModalCadastrar';
import ModalConcluir from './../../Eventos/ModalConcluir';

// componente
export default function Eventos({
    idResponsavel,
    data
}){

    // estados
    const { painel } = useSelector(state => state);
    const history = useHistory();
    const [carregando, alterarCarregando] = useState(true);
    const [registros, alterarRegistros] = useState([]);
    const [filtro, alterarFiltro] = useState({
        data: null,
        id_responsavel: idResponsavel,
        concluido: ['N'],
        ativo: ['S']
    });
    const [cadastroEvento, alterarCadastroEvento] = useState(null);
    const [concluirEvento, alterarConcluirEvento] = useState(null);

    // ao alterar responsavel
    useEffect(() => {
        alterarFiltro({...filtro, id_responsavel: idResponsavel, data: data});
    }, [data, idResponsavel]);

    // inicializa
    useEffect(() => {
        
        // consulta os eventos
        fazerConsultarRegistros();

    }, [filtro]);

    // carrega os dados
    async function fazerConsultarRegistros(){

        // se nulo não é feito nada
        if(filtro.data === null){
            return;
        }

        // inicia
        alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/eventos/ateData`, { params: filtro });

            // console.log(data);
            alterarRegistros(data.registros);
            alterarCarregando(false);

        }catch({response}){}

        // // faz a requisição
        // EventosServico.consultarTodosAteAData(filtro, login.dadosUsuario.token)
        // .then(({data, ...retorno}) => {
        //     console.log(data);
        //     alterarRegistros(data.registros);
        //     alterarCarregando(false);
        // });
    }

    return <>

        {cadastroEvento &&
            <EventosModalCadastrar 
                fechou={() => {
                    alterarCadastroEvento(null);
                }}
                salvou={() => {
                    fazerConsultarRegistros();
                }}
                registro={cadastroEvento}
            />
        }

        {concluirEvento &&
            <ModalConcluir 
                registro={concluirEvento}
                fechou={() => {
                    alterarConcluirEvento(null);
                }}
                salvou={() => {
                    fazerConsultarRegistros();
                }}
            />
        }

        <div className={styles.eventos}>
            {carregando &&
                <div className={[styles.carregador, 'rounded-pill p-1 px-3'].join(' ')}>
                    <FontAwesomeIcon className={styles.icone} pulse icon={["fas", 'spinner']} />
                    <span>Carregando eventos</span>
                </div>
            }
            {registros.map((evento, a) => 
                <Nota 
                    key={`${a}.${evento.id}`}
                    dados={evento}
                    coresEventos={painel.configuracoesSistema.coresEventos}
                    clicouConcluir={() => {
                        alterarConcluirEvento(evento);
                    }}
                    clicouEditar={() => {
                        alterarCadastroEvento(evento);
                    }}
                    clicouFicha={() => {
                        history.push(`/painel/fichas/${evento._dadosCliente.id}/alterar/${evento.id_alvo}`);
                    }}
                    clicouCliente={() => {                        
                        // se for cliente
                        if(evento.alvo_tabela === 'clientes') {
                            history.push(`/painel/clientes/alterar/${evento.id_alvo}`);
                        } else {
                            history.push(`/painel/clientes/alterar/${evento._dadosCliente.id}`);
                        }
                    }}
                    clicouEmail={() => {
                        // se for cliente
                        if(evento.alvo_tabela === 'clientes') {
                            history.push(`/painel/clientes/enviar-email/${evento.id_alvo}`);
                        } else {
                            history.push(`/painel/clientes/enviar-email/${evento._dadosCliente.id}`);
                        }
                    }}
                    clicouSms={() => {
                        // se for cliente
                        if(evento.alvo_tabela === 'clientes') {
                            history.push(`/painel/clientes/enviar-sms/${evento.id_alvo}`);
                        } else {
                            history.push(`/painel/clientes/enviar-sms/${evento._dadosCliente.id}`);
                        }
                    }}
                    clicouWhatsapp={() => {
                        // se for cliente
                        if(evento.alvo_tabela === 'clientes') {
                            history.push(`/painel/clientes/enviar-whatsapp/${evento.id_alvo}`);
                        } else {
                            history.push(`/painel/clientes/enviar-whatsapp/${evento._dadosCliente.id}`);
                        }
                    }}
                />    
            )}
        </div>
    </>
}

