import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

// componentes
import { Button } from 'react-bootstrap';
import Ficha from './Ficha';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GerarRelatorio from './GerarRelatorio';

// faz a consulta
export default function Consultar({
    idCliente
}){
    
    // configuracoesSistema.coresEventos
    
    // let tipos = {
    //     1: 'tipo-direito',
    //     2: 'tipo-seguro',
    //     3: 'tipo-consultoria',
    //     4: 'tipo-avaliacao',
    //     5: 'tipo-imoveis',
    //     6: 'usuario',
    //     7: 'cliente'
    // };

    // estados
    const configuracoesSistema = useSelector(state => state.painel.configuracoesSistema);
    const history = useHistory();
    const [carregando, alterarCarregando] = useState(true);
    const [registros, alterarRegistros] = useState([]);
    const [relatorio, alterarRelatorio] = useState(false);    

    // fazer a consulta inicial
    async function fazerConsulta(){

        // faz a requisição
        try {

            // requisição
            const { data, ...retorno } = await axios.get(`/fichas`, {
                params: {
                    tipo: [], // todos, ou [1, 2]
                    ativo: ['S'],
                    id_cliente: idCliente
                }
            });

            // finaliza
            alterarRegistros(data.registros);            
        } catch({ response }) {
            console.error(response);
        } finally {
            alterarCarregando(false);
        }

    }

    useEffect(() => {
        // faz a consulta inicial
        fazerConsulta();
    }, []);

    // carregando
    if(carregando) return <div>Carregando fichas...</div>

    return <>
        {relatorio &&
            <GerarRelatorio 
                idCliente={idCliente}
                fechou={() => {
                    alterarRelatorio(false);
                }}
            />
        }

        <p className='text-right'>
            <Button 
                variant='padrao'
                className={'mr-2'}
                // disabled={gerandoRelatorio}
                // onClick={gerarRelatorio}
                onClick={() => {
                    alterarRelatorio(true);
                }}
                size="sm"
            >   
                <FontAwesomeIcon className='icone' icon={["fas", 'print']} />
                <span>Relatório</span>
            </Button>
            <Button 
                variant='padrao'
                onClick={() => {
                    history.push(`/painel/fichas/${idCliente}/cadastrar`);
                }}
                size="sm"
            >   
                <FontAwesomeIcon className='icone' icon={["fas", 'clipboard-list']} />
                <span>Atendimento Novo</span>
            </Button>

        </p>
        <div className={styles.fichas}>
            {registros.map((registro, index) => 
                <Ficha 
                    key={registro.dados.id} 
                    fichaAnteriorRenovacao={registros[index - 1] ? (registros[index - 1]?.dados?.id_ficha_renovado ? true : false) : false}
                    dados={registro.dados}
                    proposta={registro.proposta}
                    emissao={registro.emissao}
                    finalizado={registro.finalizado}
                    coresEventos={configuracoesSistema.coresEventos}
                    clicouEditar={() => {
                        history.push(`/painel/fichas/${idCliente}/alterar/${registro.dados.id}`);
                    }}
                />
            )}
        </div>
    </>
}