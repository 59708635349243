import React, { useContext } from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';

import { ContainerComponent } from './components';
import { FichaCadastrarContexto } from './../FichaCadastrarContexto';
import AnexosFicha from './../AnexosFicha';

export default function Formulario({
    idFichaRenovar,
    clicouRenovar
}){

    // estados
    const { 
        dados, 
        salvando,
        alterarDados, 
        dadosEmissao, 
        dadosProposta, 
        anexosFormulario, 
        idFichaRenovacao, 
        alterarAnexosFormulario
    } = useContext(FichaCadastrarContexto);

    return <>
        <ContainerComponent
            etapa={dados.etapa}
            cancelado={false}
            edicao={dados.id !== null ? true : false}
        >
            <p className="m-0 text-secondary mb-1"><FontAwesomeIcon icon={faClipboardList} /> Atendimento</p>
            <Row>
                <Col lg={8} className="coluna-formulario">
                    <Card className={'mb-3 border-0 shadow-sm'}>
                        <Card.Body className="d-flex justify-content-around">
                            {[
                                {id: '1', descricao: 'Direito'}, 
                                {id: '2', descricao: 'Seguro'},
                                {id: '3', descricao: 'Consultoria'},
                                {id: '4', descricao: 'Avaliação'},
                                {id: '5', descricao: 'Imóveis'},
                            ].map(tiposFicha => 
                                <Form.Check
                                    key={tiposFicha.id}
                                    inline
                                    label={tiposFicha.descricao}
                                    type={'radio'}
                                    checked={(tiposFicha.id === dados.tipo)? true : false}
                                    id={`custom-inline-tipo-cliente-${tiposFicha.id}`}
                                    name='tipo_cliente'
                                    onChange={e => {
                                        dados.tipo = tiposFicha.id;
                                        alterarDados({...dados});
                                    }}
                                    disabled={(dadosProposta !== null) || (dadosEmissao !== null) || (idFichaRenovacao !== null)}
                                />
                            )}
                        </Card.Body>
                    </Card>
                    <Card className={'border-0 shadow-sm'}>
                        <Card.Body>
                            <Form.Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Titulo</Form.Label>
                                        <Form.Control 
                                            value={dados.titulo}
                                            onChange={e => {alterarDados({...dados, titulo: e.target.value})}}
                                            maxLength={200}
                                        />
                                    </Form.Group>
                                </Col>
                                { dados.tipo === '2' &&
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Placa veículo</Form.Label>
                                            <Form.Control 
                                                value={dados.placa_veiculo || ''}
                                                onChange={e => {alterarDados({...dados, placa_veiculo: e.target.value})}}
                                                maxLength={30}
                                            />
                                        </Form.Group>
                                    </Col>
                                }
                            </Form.Row>
                            <Form.Group>
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control 
                                    value={dados.descricao}
                                    onChange={e => {alterarDados({...dados, descricao: e.target.value})}}
                                    as='textarea'
                                    rows='5'
                                />

                            </Form.Group>

                            <Form.Group className="text-right">
                                <Form.Check
                                    inline
                                    label="É uma cotação?"
                                    type={'checkbox'}
                                    checked={(dados.cotacao === 'S')? true : false}
                                    id={`checkbox-cotacao`}
                                    onChange={(e) => {
                                        alterarDados({
                                            ...dados,
                                            cotacao: e.target.checked ? 'S' : 'N'
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} className="coluna-anexos">                    
                    {(!idFichaRenovar && dados.id) && <Card className={'border-0 shadow-sm card-renovar mb-3'}>
                        <Card.Body className="text-center p-3">
                            <Button 
                                variant={'success'} 
                                size="sm" 
                                className="w-100"
                                onClick={clicouRenovar}
                            >
                                Renovar
                            </Button>
                        </Card.Body>
                    </Card>}
                    

                    <Card className={'mb-3 border-0 shadow-sm'}>
                        <Card.Body className="">
                            <Form.Group>
                                <Form.Label>Observação</Form.Label>
                                <Form.Control 
                                    value={dados.observacao}
                                    onChange={e => {alterarDados({...dados, observacao: e.target.value})}}
                                    as='textarea'
                                />
                            </Form.Group>

                        </Card.Body>
                    </Card>

                    <Card className="border-0 shadow-sm">
                        <Card.Body>
                            <AnexosFicha 
                                anexos={anexosFormulario}
                                alterar={alterarAnexosFormulario}
                                height={143}
                                salvando={salvando}
                            />
                        </Card.Body>
                    </Card>
                    
                </Col>
            </Row>
        </ContainerComponent>
    </>
}