import React from 'react';
import { 
    Tooltip, 
    ButtonToolbar, 
    OverlayTrigger 
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '@mdi/react';

import styles from './index.module.scss';

// botão para tabelas
function BotaoTabela({
    carregando=false,
    tamanhoIcone=undefined,
    estiloBotao={},
    iconeLib='fontAwesome',
    ...props
}){
    
    // botao
    return <ButtonToolbar style={{
        display: 'inline-flex'
    }}>
        <OverlayTrigger 
            placement='top'
            overlay={
                <Tooltip id={`tooltip-top`}>
                    {props.children}
                </Tooltip>
            }
        >            
            <button 
                disabled={carregando}
                className={(props.classe || '') + ' ' + styles.botao}
                {...{
                    onClick: e => {
                        e.stopPropagation();
                        if(props.onClick) props.onClick(e) 
                    },
                }}
                style={estiloBotao}                
            >
                {carregando ?
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    :
                    iconeLib === "fontAwesome"? <FontAwesomeIcon 
                        style={{ color: props.color }} 
                        className="icone" 
                        icon={props.icone} 
                        size={tamanhoIcone}
                    /> : (iconeLib === "mdi" ? <Icon path={props.icone}
                        className="icone" 
                        size={tamanhoIcone}
                        color={props.color}
                        style={props.estiloIcone ?? {}}
                    /> : <></>)
                }                
            </button>
        </OverlayTrigger>
    </ButtonToolbar>

}


export default BotaoTabela;