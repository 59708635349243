import React, { memo } from 'react';
import styles from './index.module.scss';
import moment from 'moment';

// componentes 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Opcoes from './Opcoes';

// funções
import { estiloEvento } from './../../../ConfiguracaoSistema';

function Nota({
    dados,
    clicouWhatsapp,
    coresEventos,
    clicouEditar,
    clicouCliente,
    clicouFicha,
    clicouConcluir,
    clicouEmail,
    clicouSms
}){


    // dados.status_programacao
    // 1 concluido
    // 2 a vencer
    // 3 vence hoje
    // 4 atrasado

    // let tipos = {
    //     1: 'tipo-direito',
    //     2: 'tipo-seguro',
    //     3: 'tipo-consultoria',
    //     4: 'tipo-avaliacao',
    //     5: 'tipo-imoveis',
    //     6: 'usuario',
    //     7: 'cliente'
    // };

    // estilo do card
    let estilo = {
        background: '',
        color: ''
    };

    // altera estilo do card
    if(dados.alvo_tabela === 'clientes'){
        estilo.background = coresEventos['cliente'].fundo;
        estilo.color = coresEventos['cliente'].texto;
    }else{
        estilo = estiloEvento(coresEventos, dados._dadosConsulta.tipo);
    }

    let estadoNota = '';
    switch(dados.status_programacao){
        case 1: estadoNota = styles.concluido; break;
        case 2: estadoNota = styles.aVencer; break;
        case 3: estadoNota = styles.venceHoje; break;
        case 4: estadoNota = styles.atrasado; break;
    }

    return <div className={[styles.nota, estadoNota].join(' ')}>
        <blockquote className='rounded' style={estilo}>

            {/* evento recorrente */}
            {dados._ficticio && 
                <FontAwesomeIcon title='Evento Recorrente' className={styles.recorrente} icon={["fas", 'retweet']} />
            }

            <p className={styles.descricao}>{dados.descricao}</p>
            {dados.alvo_tabela === 'clientes_fichas' &&
                <cite className={styles.autor}>{dados._dadosCliente.nome}</cite>
            }
            {dados.alvo_tabela === 'clientes' &&
                <cite className={styles.autor}>{dados._dadosConsulta.nome}</cite>
            }
            
            <span className={styles.data}>
                { dados.data_reagendamento !== null ?
                    <>
                        <FontAwesomeIcon title='Reagendado para' className={styles.reagendado} icon={["far", 'clock']} />
                        {moment(dados.data_reagendamento).format('DD/MM/YYYY')}
                    </>
                    :
                    <>
                        {moment(dados.data_programada).format('DD/MM/YYYY')}
                    </>
                }
                
            </span>

            <Opcoes 
                className={styles.opcoes} 
                dados={dados}
                clicouEditar={clicouEditar}
                clicouCliente={clicouCliente}
                clicouFicha={clicouFicha}
                clicouConcluir={clicouConcluir}
                clicouEmail={clicouEmail}
                clicouSms={clicouSms}
                clicouWhatsapp={clicouWhatsapp}
            />
        </blockquote>
        <i className={styles.pin}></i>
        
    </div>
}

export default memo(Nota);